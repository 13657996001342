import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase'; // adjust path as necessary
import { getAuth } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';

const Programs = ({ clientId }) => {
  const [clientGroups, setClientGroups] = useState([]); // Store client's current groups
  const [allGroups, setAllGroups] = useState([]); // Store all available groups (for dropdown)
  const [selectedGroup, setSelectedGroup] = useState(''); // Track selected group
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const auth = getAuth();
  const coachId = auth.currentUser.uid;
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientGroups();
    fetchAllGroups();
    // eslint-disable-next-line 
  }, [clientId]);

  // Fetch groups the client is part of
  const fetchClientGroups = async () => {
    const clientRef = doc(db, 'users', clientId);
    const clientDoc = await getDoc(clientRef);

    if (clientDoc.exists()) {
      const clientData = clientDoc.data();
      const groupId = clientData.fitness_group;

      if (groupId) {
        // Fetch the corresponding group name
        const groupRef = doc(db, 'fitness_group', groupId);
        const groupDoc = await getDoc(groupRef);
        
        if (groupDoc.exists()) {
          setClientGroups({ id: groupId, ...groupDoc.data() });
        }
      }
    }
  };

  // Fetch all available fitness groups
  const fetchAllGroups = async () => {
    const groupsSnapshot = await getDocs(collection(db, 'fitness_group'));
    const groupsArray = groupsSnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(group => group.coachId === coachId);
    setAllGroups(groupsArray);
  };

  // Handle group selection from dropdown
  const handleGroupChange = async (e) => {
    const newGroupId = e.target.value;
    setSelectedGroup(newGroupId);

    // Remove client from their current group and add to the new group
    const clientRef = doc(db, 'users', clientId);

    // Get the old group(s)
    const clientDoc = await getDoc(clientRef);
    const clientData = clientDoc.data();
    const oldGroupId = clientData.fitness_group;
    console.log(`Old groups: ${oldGroupId}`);

    try {
      // Remove from old group(s) and update the new group
      if (oldGroupId) {
        const oldGroupRef = doc(db, 'fitness_group', oldGroupId);
        await updateDoc(oldGroupRef, {
          members: arrayRemove(clientId)
        });
      }

      // Add to the new group
      const newGroupRef = doc(db, 'fitness_group', newGroupId);
      await updateDoc(newGroupRef, {
        members: arrayUnion(clientId)
      });

      // Update the client's document to reflect the new group
      await updateDoc(clientRef, {
        fitness_group: newGroupId
      });

      // Refresh the groups
      fetchClientGroups();
    } catch (error) {
      console.error('Error updating group assignment:', error);
    }
  };

  const handleSwitchToIndividual = async () => {
    setShowConfirmModal(true);
  };

  const confirmSwitchToIndividual = async () => {
    try {
      const clientRef = doc(db, 'users', clientId);
      const clientDoc = await getDoc(clientRef);
      const clientData = clientDoc.data();
      const groupId = clientData.fitness_group;

      if (groupId) {
        // Remove client from the group's members array
        const groupRef = doc(db, 'fitness_group', groupId);
        await updateDoc(groupRef, {
          members: arrayRemove(clientId)
        });
      }

      // Update client's document
      await updateDoc(clientRef, {
        fitness_group: null,
        programType: 'individual'
      });

      // Refresh the groups
      fetchClientGroups();
      setShowConfirmModal(false);

      // Navigate to the Programs tab
      navigate(`/dashboard/clients/${clientId}?tab=Programs`);
      window.location.reload();
    } catch (error) {
      console.error('Error switching to individual programming:', error);
    }
  };

  return (
    <div className="">
      <div className="card-header border-bottom d-flex align-items-center">
        <h5 className="me-auto h3">Group Programs</h5>
        <button className="btn h3 btn-neutral" onClick={handleSwitchToIndividual}>
          Switch client over to individual programming
        </button>
      </div>

      {/* Display client's current groups */}
      <div className="card-body">
        <h6 className="d-inline">Current Program:   </h6>
        {clientGroups ? (
              <Link to={`/dashboard/groups/${clientGroups.id}`}>
                {clientGroups.name === "summer shred" ? "Summer Shred" : clientGroups.name}
              </Link>
        ) : (
          <p>No group programs assigned.</p>
        )}

        {/* Dropdown for changing the client's group */}
        <div className="mt-3">
          <h6 htmlFor="groupDropdown">Move to a different group:</h6>
          <select
            id="groupDropdown"
            className="form-select"
            value={selectedGroup}
            onChange={handleGroupChange}
          >
            <option value="">Select a group...</option>
            {allGroups.map(group => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>



        {showConfirmModal && (
          <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Switch</h5>
                  <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to switch over? Client will be unenrolled from all group programming.
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={confirmSwitchToIndividual}>Confirm</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Programs;
