// WorkoutStart.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/firebase';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import ClientNavbar from '../../layouts/ClientNavbar';
import { generateExerciseLogId } from '../../utils/generateExerciseLogId';
import ExerciseTable from '../../components/shared/ExerciseTable';
import { getAuth } from 'firebase/auth';

const WorkoutStart = () => {
    const { workoutId } = useParams();
    const [workout, setWorkout] = useState(null);
    const [workoutData, setWorkoutData] = useState(null);
    const [completedWorkoutId, setCompletedWorkoutId] = useState(null);
    const [exerciseOrder, setExerciseOrder] = useState([]);
    const navigate = useNavigate();
    const auth = getAuth();
    const clientId = auth.currentUser.uid;
    const { username } = useParams();

    const fetchWorkout = useCallback(async () => {
        if (!workoutId) {
            console.error('No workoutId provided');
            return;
        }

        const workoutRef = doc(db, 'assigned_workouts', workoutId);
        const workoutSnap = await getDoc(workoutRef);

        if (workoutSnap.exists()) {
            const workoutData = workoutSnap.data();
            setWorkoutData(workoutData);
            
            // Create a new document in completed_workouts collection if it doesn't exist
            const completedWorkoutRef = doc(db, 'completed_workouts', workoutId);
            const completedWorkoutSnap = await getDoc(completedWorkoutRef);
            
            if (!completedWorkoutSnap.exists()) {
                let workoutType = null;
                if (workoutData.type === 'personalized') {
                    workoutType = 'personalized';
                } else if (workoutData.type === 'group') {
                    workoutType = 'group';
                }

                const completedWorkoutData = {
                    client_id: auth.currentUser.uid,
                    parentId: workoutId,
                    completion_status: false,
                    created_at: serverTimestamp(),
                    completion_timestamp: null,
                    name: workoutData.name,
                    deleted_at: null,
                    type: workoutType,
                    notes: ''
                };
                
                await setDoc(completedWorkoutRef, completedWorkoutData);
            }
            
            // Fetch or create documents in client_exercise_logs collection
            const exerciseLogs = {};
            for (const [index, exercise] of workoutData.exercises.entries()) {
                if (!exercise.name) {
                    console.error('Exercise name is undefined', exercise);
                    continue;
                }

                const exerciseLogId = generateExerciseLogId(auth.currentUser.uid, workoutId, exercise.name);
                const exerciseLogRef = doc(db, 'client_exercise_logs', exerciseLogId);
                const exerciseLogSnap = await getDoc(exerciseLogRef);
    
                let exerciseLogData;
                if (!exerciseLogSnap.exists()) {
                    // Create new exercise log if it doesn't exist
                    exerciseLogData = {
                        client_id: auth.currentUser.uid,
                        workout_id: workoutId,
                        exercise_name: exercise.name,
                        videoLink: exercise.videoLink || '',
                        sets: {},
                        created_at: serverTimestamp(),
                        deleted_at: null,
                        orderNumber: index + 1 // Add orderNumber here
                    };
    
                    for (let i = 1; i <= exercise.sets; i++) {
                        exerciseLogData.sets[i] = {
                            reps: exercise.reps || '',
                            weight: exercise.weight || '',
                            unitType: exercise.unitType || '',
                            completed: false,
                            completed_at: null
                        };
                    }
    
                    await setDoc(exerciseLogRef, exerciseLogData);
                } else {
                    // Use existing exercise log only if deleted_at is null
                    exerciseLogData = exerciseLogSnap.data();
                    if (exerciseLogData.deleted_at !== null) {
                        continue; // Skip this exercise log if it's deleted
                    }
                }
    
                exerciseLogs[exercise.name] = exerciseLogData;
            }
            
            setWorkout(exerciseLogs);
            setExerciseOrder(workoutData.exercises.map(exercise => exercise.name));
            setCompletedWorkoutId(workoutId);
        } else {
            console.log('No such document!');
        }
    }, [workoutId]);

    useEffect(() => {
        fetchWorkout();
    }, [fetchWorkout]);

    const handleComplete = useCallback(async () => {
        if (!completedWorkoutId) {
            console.error('No completedWorkoutId available');
            return;
        }

        const completedWorkoutRef = doc(db, 'completed_workouts', completedWorkoutId);
        try {
            await updateDoc(completedWorkoutRef, { 
                completion_status: true,
                completion_timestamp: serverTimestamp()
            });
            navigate(`/website/${username}/dashboard`);
        } catch (error) {
            console.error('Error updating workout: ', error);
        }
    }, [completedWorkoutId, navigate, username]);

    if (!workout) {
        return <p>Loading...</p>;
    }

    return (
        <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
            <ClientNavbar username={username}/>
            <div className="h-screen flex-grow-1 overflow-y-lg-auto">
                <header className="bg-surface-primary border-bottom pt-6 sticky-top">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="h2 mb-0 ls-tight">{workout[exerciseOrder[0]]?.name || 'Workout'}</h1>
                            <button className="btn btn-light" onClick={() => navigate(-1)}>X</button>
                        </div>
                    </div>
                </header>

                <div className="card rounded-0">
                    <div className="card-body">
                        <ExerciseTable 
                            workout={workout}
                            isEditable={false}
                            workoutId={workoutId}
                            setWorkout={setWorkout}
                            clientId={clientId}
                            workoutData={workoutData}
                            exerciseOrder={exerciseOrder}
                            handleComplete={handleComplete}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkoutStart;
