// exerciseUtils.js
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';

// Function to fetch exercises
export const fetchExercises = async (coachId) => {
  try {
    // Fetch coach-specific exercises
    const coachExercisesSnapshot = await getDocs(collection(db, "users", coachId, "exercises"));
    const coachExercises = coachExercisesSnapshot.docs.map(doc => ({
      label: doc.data().name,
      value: doc.id,
      defaultSets: doc.data().defaultSets || '',
      defaultUnits: doc.data().defaultUnits || '',
      unitType: doc.data().unitType || 'reps',
      defaultWeight: doc.data().defaultWeight || ''
    }));

    // Fetch general exercises
    const generalExercisesSnapshot = await getDocs(collection(db, "exercises"));
    const generalExercises = generalExercisesSnapshot.docs.map(doc => ({
      label: doc.data().name,
      value: doc.id,
      defaultSets: doc.data().defaultSets || '',
      defaultUnits: doc.data().defaultUnits || '',
      unitType: doc.data().unitType || 'reps',
      defaultWeight: doc.data().defaultWeight || ''
    }));

    // Combine and remove duplicates
    const combinedExercises = [...coachExercises, ...generalExercises];
    const uniqueExercises = Array.from(new Map(combinedExercises.map(ex => [ex.label, ex])).values());

    return uniqueExercises;
  } catch (error) {
    console.error("Error fetching exercises: ", error);
    return [];
  }
};
