import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const ClientAddCreditsModal = ({ onClose, coachObject, coachId, clientId, onTransactionAdded }) => {
    const [customQuantity, setCustomQuantity] = useState(1); // Default to 1 session

    // Calculate the price for the credit packs
    const calculatePrice = (quantity, discount) => {
        return quantity * coachObject.creditCost * (1 - discount);
    };

    // Pre-calculate prices for packages
    const pack1Price = calculatePrice(coachObject.pack1Quantity, coachObject.pack1Discount);
    const pack2Price = calculatePrice(coachObject.pack2Quantity, coachObject.pack2Discount);
    const customPrice = calculatePrice(customQuantity, 0); // No discount for custom quantity

    const handleCheckout = async (quantity, total) => {
        if (!coachObject.stripeAccountId || !coachObject.allowClientPurchase) {
            console.error("Stripe account ID is missing or client purchase is not allowed.");
            alert("Unable to proceed with the purchase. Please contact support.");
            return;
        }

        try {
            const currentUrl = window.location.href; 

            const unit_quantity = quantity;
            const unit_amount = Math.round(total / quantity * 100);
            const application_fee = Math.round(total * 0.03 * 100);
            const product_name = `Credits with ${coachObject.username} (${coachObject.firstName})`;
            const cancel_url = currentUrl;

            console.log('Sending request to create checkout session:', {
                accountId: coachObject.stripeAccountId,
                unit_quantity,
                unit_amount,
                application_fee,
                product_name,
                cancel_url,
                metadata: {
                    clientId,
                    coachId,
                    creditsPurchased: unit_quantity,
                    product_name
                }
            });

            const response = await fetch(`${window.location.origin}/api/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accountId: coachObject.stripeAccountId,
                    unit_quantity,
                    unit_amount,
                    application_fee,
                    product_name,
                    cancel_url,
                    metadata: {
                        clientId,
                        coachId,
                        creditsPurchased: unit_quantity,
                        product_name
                    }
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('API response error:', response.status, errorText);
                throw new Error(`Failed to create checkout session: ${response.status} ${errorText}`);
            }

            const { url } = await response.json(); // Destructure the session URL from the response
            console.log('Received session URL:', url);
    
            // Redirect to Stripe Checkout using the session URL
            window.location.href = url;

        } catch (error) {
            console.error("Error during Stripe Checkout:", error);
            alert(`Error initiating Stripe Checkout: ${error.message}`);
        }
    };

    if (!coachObject.stripeAccountId || !coachObject.allowClientPurchase) {
        return null; // Don't render the modal if conditions are not met
    }

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Buy Credits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{paddingBottom: '2rem'}}>
                    Buy a credit pack to more easily book and to spend less on appointments.
                </div>

                <div className="list-group list-group-flush">
                    {/* Pre-defined Pack 1 */}
                    <div className="list-group-item d-flex align-items-center px-0">
                        <div className="flex-fill">
                            {/* Title */}
                            <a href="#" className="d-block h6 font-semibold mb-1">
                                {coachObject.pack1Quantity} credits
                            </a>
                            {/* Subtitle */}
                            <span className="d-block text-sm mb-2"><em>Purchase this pack for a {coachObject.pack1Discount * 100}% discount</em></span>
                            {/* Badges */}
                            <div className="d-flex mx-n1"></div>
                        </div>
                        <div className="ms-auto text-end">
                            <a onClick={() => handleCheckout(coachObject.pack1Quantity, pack1Price)} className="btn btn-sm btn-primary">
                                ${pack1Price.toFixed(2)}
                            </a>
                        </div>
                    </div>

                    {/* Pre-defined Pack 2 */}
                    <div className="list-group-item d-flex align-items-center px-0">
                        <div className="flex-fill">
                            {/* Title */}
                            <a href="#" className="d-block h6 font-semibold mb-1">
                                {coachObject.pack2Quantity} credits
                            </a>
                            {/* Subtitle */}
                            <span className="d-block text-sm mb-2">
                                <em>Purchase this pack for a {coachObject.pack2Discount * 100}% discount</em>
                            </span>
                            {/* Badges */}
                            <div className="d-flex mx-n1"></div>
                        </div>
                        <div className="ms-auto text-end">
                            <a onClick={() => handleCheckout(coachObject.pack2Quantity, pack2Price)} className="btn btn-sm btn-primary">
                                ${pack2Price.toFixed(2)}
                            </a>
                        </div>
                    </div>

                    {/* Custom Pack */}
                    <div className="list-group-item d-flex align-items-center px-0">
                        <div className="flex-fill">
                            {/* Title */}
                            <a href="#" className="d-block h6 font-semibold mb-1">
                                Custom Quantity (Base Price)
                            </a>
                            {/* Subtitle */}
                            <span className="d-block text-sm mb-2">
                                <Form.Group className="d-flex align-items-center">
                                    <Form.Control 
                                        style={{width: '80px'}}
                                        type="number"
                                        value={customQuantity} 
                                        onChange={e => setCustomQuantity(e.target.value)}
                                    />
                                    <span className="ms-2 d-inline">credits</span>
                                </Form.Group>
                            </span>
                            {/* Badges */}
                            <div className="d-flex mx-n1"></div>
                        </div>
                        <div className="ms-auto text-end">
                            <a onClick={() => handleCheckout(customQuantity, customPrice)} className="btn btn-sm btn-primary">
                                ${customPrice.toFixed(2)} {/* No discount for custom quantity */}
                            </a>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ClientAddCreditsModal;
