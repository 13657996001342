import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../../firebase/firebase'; // adjust path as necessary
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Programs = ({ clientId }) => {
  const navigate = useNavigate();
  const [workouts, setWorkouts] = useState([]);
  const [filter, setFilter] = useState('Upcoming'); // Default to 'Upcoming'
  const [isLoading, setIsLoading] = useState(true);
  const [completedWorkouts, setCompletedWorkouts] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: 'dateAssigned', direction: 'ascending' });

  useEffect(() => {
    const fetchWorkouts = async () => {
      setIsLoading(true);
      try {
        const workoutsQuery = query(collection(db, 'assigned_workouts'), where('clientId', '==', clientId));
        const workoutsSnapshot = await getDocs(workoutsQuery);
        const workoutsData = workoutsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          dateAssigned: doc.data().dateAssigned instanceof Date ? doc.data().dateAssigned : new Date(doc.data().dateAssigned.replace(/-/g, '/')),
        }));

        const completedWorkoutsQuery = query(collection(db, 'completed_workouts'), where('client_id', '==', clientId));
        const completedWorkoutsSnapshot = await getDocs(completedWorkoutsQuery);

        console.log(completedWorkoutsSnapshot);
        const completedWorkoutsData = {};
        completedWorkoutsSnapshot.docs.forEach(doc => {
          completedWorkoutsData[doc.id] = doc.data().completion_status;
        });
        
        console.log(completedWorkoutsData);

        setWorkouts(workoutsData);
        setCompletedWorkouts(completedWorkoutsData);
      } catch (error) {
        console.error("Error fetching workouts:", error);
        setWorkouts([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkouts();
  }, [clientId]);

  const handleCreateWorkout = () => {
    navigate(`/dashboard/clients/${clientId}/create-workout`);
  };

  const handleEditWorkout = (workoutId) => {
    navigate(`/dashboard/clients/${clientId}/edit-workout/${workoutId}`);
  };

  const handleDeleteWorkout = async (workoutId) => {
    try {
      await deleteDoc(doc(db, 'assigned_workouts', workoutId));
      setWorkouts(prev => prev.filter(workout => workout.id !== workoutId));
    } catch (error) {
      console.error("Error deleting workout:", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSwitchToGroupWorkouts = async () => {
    try {
      const userRef = doc(db, 'users', clientId);
      await updateDoc(userRef, {
        programType: 'group'
      });
      alert('Client switched to group workouts successfully');
      navigate(`/dashboard/clients/${clientId}?tab=Programs`);
      window.location.reload();
    } catch (error) {
      console.error("Error switching to group workouts:", error);
      alert('Failed to switch to group workouts');
    }
  };

  const filteredWorkouts = workouts.filter(workout => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison
    if (filter === 'Upcoming') return workout.dateAssigned >= today;
    if (filter === 'Completed') return workout.dateAssigned < today;
    return true; // 'All active'
  });

  const getStatusIcon = (workout) => {
    if (completedWorkouts[workout.id] === undefined) {
      return <i className="bi bi-circle text-muted ms-2" title="Workout Not Started" />;
    } else if (completedWorkouts[workout.id] === false) {
      return <i className="bi bi-check-circle-fill text-warning ms-2" title="Workout Started" />;
    } else {
      return <i className="bi bi-check-circle-fill text-success ms-2" title="Workout Completed" />;
    }
  };

  const sortedWorkouts = React.useMemo(() => {
    let sortableWorkouts = [...filteredWorkouts];
    if (sortConfig !== null) {
      sortableWorkouts.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableWorkouts;
  }, [filteredWorkouts, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? ' ↑' : ' ↓';
    }
    return '';
  };

  return (
    <div className="">
      <div className="card-header border-bottom d-flex align-items-center">
        <h5 className="me-auto h3">Personalized Workout Plans</h5>
        <button className="ms-3" onClick={handleCreateWorkout}>
          + Add New Workout
        </button>
        <button className="btn btn-sm btn-primary ms-3" onClick={handleSwitchToGroupWorkouts}>
          Switch to Group Workouts
        </button>
        <select className="btn btn-sm btn-neutral dropdown-toggle ms-3" onChange={handleFilterChange} value={filter}>
          <option value="All active">All Active</option>
          <option value="Upcoming">Upcoming</option>
          <option value="Completed">Completed</option>
        </select>
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-nowrap">
          <thead className="table-light">
            <tr>
              <th scope="col" onClick={() => requestSort('name')}>Workout Name{getSortIcon('name')}</th>
              <th scope="col" onClick={() => requestSort('dateAssigned')}>Date Assigned{getSortIcon('dateAssigned')}</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="text-center">Loading...</td>
              </tr>
            ) : sortedWorkouts.length > 0 ? (
              sortedWorkouts.map(workout => (
                <tr key={workout.id}>
                  <td>{workout.name ? workout.name.charAt(0).toUpperCase() + workout.name.slice(1) : 'Unnamed Workout'}</td>
                  <td>{workout.dateAssigned.toLocaleDateString()}</td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-${workout.id}`}>{getStatusIcon(workout).props.title}</Tooltip>}
                    >
                      <span>{getStatusIcon(workout)}</span>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <button onClick={() => handleEditWorkout(workout.id)}>Edit</button>
                    <button className="ms-2" onClick={() => handleDeleteWorkout(workout.id)}>Delete</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">No workouts created yet</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Programs;
