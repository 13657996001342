// CreateWorkout.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, addDoc, getDocs, doc, updateDoc, arrayUnion, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../layouts/Coachportal_sidebar';
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import WorkoutDetailsForm from '../../components/coachportal/workoutDetailsForm';
import ExercisesTable from '../../components/coachportal/addExercisesTable';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { fetchExercises } from '../../utils/exerciseUtils';  // Import the utility function


const CreateWorkout = ({ type }) => {
  const [completedWorkoutDetails, setCompletedWorkoutDetails] = useState({
    workoutName: '',
    duration: '',
    difficulty: 'easy',
    dateAssigned: ''
  });
  const [exercises, setExercises] = useState(Array(5).fill().map(() => ({ name: '', sets: '', reps: '', weight: '', unitType: 'reps' })));
  const [exerciseOptions, setExerciseOptions] = useState([]);

  const [defaultExercises, setDefaultExercises] = useState([]); // Array for all exercise names

  const [videoLink, setVideoLink] = useState(''); // Video link state
  const [videoModal, setVideoModal] = useState(false); // Video link state
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(null);

  const navigate = useNavigate();
  const auth = getAuth();
  const coachId = auth.currentUser.uid;
  const { id, groupId, workoutId } = useParams(); // Added workoutId to useParams

    // Custom Dropdown Indicator component that returns null effectively removing the arrow
    const DropdownIndicator = props => {
        return null;
    };

  // Fetch exercises from Firestore
  useEffect(() => {
    const loadExercises = async () => {
      const exercises = await fetchExercises(coachId);  // Use the utility function
      setExerciseOptions(exercises);

      // Store exercise names in default_exercises
      const exerciseNames = exercises.map(ex => ex.label);
      setDefaultExercises(exerciseNames);
    };

    loadExercises();
  }, [coachId]);

  // Video modal handlers
  const handleVideoIconClick = (index) => {
    setCurrentExerciseIndex(index);
    setVideoLink(exercises[index].videoLink || ''); // Set the current video link
    setVideoModal(true); // Open the modal
  };

  const handleSaveVideoLink = () => {
    const newExercises = exercises.map((exercise, i) => {
      if (i === currentExerciseIndex) {
        return { ...exercise, videoLink };
      }
      return exercise;
    });
    setExercises(newExercises);
    setVideoModal(false);
  };

  // Handle exercise changes
  const handleExerciseChange = (index, field, value, selectedOption) => {
    const newExercises = exercises.map((exercise, i) => {
      if (i === index) {
        if (field === 'name' && selectedOption) { // Only update other fields if the name field is being changed to a selected option
          return {
            ...exercise,
            name: value,
            sets: selectedOption.defaultSets,
            reps: selectedOption.defaultUnits,
            unitType: selectedOption.unitType,
            weight: selectedOption.defaultWeight,
          };
        }
        return { ...exercise, [field]: value };
      }
      return exercise;
    });
    setExercises(newExercises);
  };

  // Handle adding and deleting exercises
  const handleAddExercise = () => {
    setExercises([...exercises, { name: '', sets: '', reps: '', weight: '', unitType: 'reps' }]);
  };

  const handleDeleteExercise = index => {
    // Remove the exercise at the given index
    setExercises(currentExercises => currentExercises.filter((_, i) => i !== index));
  };

  // Handle new exercise creation
  const handleCreateExercise = async (inputValue, index) => {
    try {
      const newExerciseRef = await addDoc(collection(db, "users", coachId, "exercises"), { name: inputValue });
      const newOption = { label: inputValue, value: newExerciseRef.id };
      setExerciseOptions(prevOptions => [...prevOptions, newOption]);
      handleExerciseChange(index, 'name', newOption.label);
    } catch (error) {
      console.error("Error creating new exercise: ", error);
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const filledExercises = exercises.filter(ex => ex.name || ex.sets || ex.reps);
    for (let exercise of filledExercises) {
      if (!exercise.name || !exercise.sets || !exercise.reps) {
        alert('Please complete all fields for each exercise that has any information entered.');
        return;
      }
    }

    const workoutData = {
      ...completedWorkoutDetails,
      exercises: filledExercises,
      created_at: new Date(),
      type,
      ...(type === 'personalized' ? { clientId: id } : {}),
      ...(type === 'group' ? { groupId: groupId } : {})
    };

    try {
      const docRef = await addDoc(collection(db, 'assigned_workouts'), workoutData);
      console.log("Workout created with ID: ", docRef.id);

      // Update the cohort's document
      if (type === 'group') {
        const cohortRef = doc(db, 'fitness_group', groupId);
        await updateDoc(cohortRef, {
          workouts: arrayUnion(docRef.id)  // Update the cohort to reference the new workout
        });
      }

      console.log("cohort updated");

      // Smartly navigate based on the workout type
      if (type === 'personalized') {
        navigate(`/dashboard/clients/${id}?tab=Programs`); // Navigate to client details page, Programs tab
      } else if (type === 'group') {
        navigate(`/dashboard/groups/${groupId}#workouts`); // Navigate to group details page
      } else {
        navigate(-1); // Fallback to previous page if type is unknown
      }
    } catch (error) {
      console.error('Error creating workout:', error);
    }
  };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />
      <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-lg-auto">
        <Header title={`Create ${type === 'personalized' ? 'Personalized' : 'Group'} Workout`} />
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>

            <WorkoutDetailsForm
              completedWorkoutDetails={completedWorkoutDetails}
              setCompletedWorkoutDetails={setCompletedWorkoutDetails}
              setExercises={setExercises}
              coachId={coachId}
              type={type}
              groupId={groupId}
              clientId={id}
              workoutId={workoutId} // Pass workoutId to WorkoutDetailsForm
              workoutCollection = "assigned_workouts"
              defaultExercises={defaultExercises}
            />

            <ExercisesTable
              exercises={exercises}
              exerciseOptions={exerciseOptions}
              handleExerciseChange={handleExerciseChange}
              handleCreateExercise={handleCreateExercise}
              handleAddExercise={handleAddExercise}
              handleDeleteExercise={handleDeleteExercise}
              handleVideoIconClick={handleVideoIconClick}
              workoutId={workoutId} // Pass workoutId to ExercisesTable
            />

            <button type="submit" className="mt-3  mb-6 btn btn-success w-100">Create Workout</button>
          </form>
        </div>

        {/* Modal for Editing Video link */}
        <Modal show={videoModal} onHide={() => setVideoModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Video Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Enter video URL"
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setVideoModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveVideoLink}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CreateWorkout;
