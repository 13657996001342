import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../layouts/Coachportal_sidebar';
import Header from '../../layouts/Header';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { createAppointment } from '../../utils/appointments';
import { auth } from '../../firebase/firebase';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getDoc, doc, collection, query, where, getDocs, getCountFromServer, orderBy, limit } from 'firebase/firestore';
import { db } from '../../firebase/firebase';


//Weather Icon utility function
const getTimeIcon = (date) => {
  const hour = date.getHours();
  if (hour >= 0 && hour < 5) {
    return "bi bi-moon-fill fs-6 me-3";
  } else if (hour >= 5 && hour < 10) {
    return "bi bi-brightness-alt-high-fill fs-4 me-2";
  } else if (hour >= 10 && hour < 17) {
    return "bi bi-brightness-high-fill fs-4 me-2";
  } else if (hour >= 17 && hour < 20) {
    return "bi bi-sunset-fill fs-4 me-2";
  } else {
    return "bi bi-moon-fill fs-6 me-3"; // Default to moon for any other time
  }
};

//Type Icon utility function
const getTypeIcon = (type) => {
  switch (type) {
    case 'private':
      return 'bi bi-person-fill fs-4 me-2';
    case 'group':
      return 'bi bi-people-fill fs-4 me-2';
    default:
      return 'bi bi-person-fill fs-4 me-2'; // Default or fallback icon
  }
};

// Utility function to get location icon based on type
const getLocationIcon = (location) => {
  switch (location) {
    case 'in person':
      return 'bi bi-building-fill fs-5 me-2';
    case 'virtual':
      return 'bi bi-laptop-fill fs-4 me-2';
    default:
      return 'bi bi-question-circle'; // Default/fallback icon
  }
};

const calculatePercentageChange = (current, previous) => {
  if (previous === 0) return current === 0 ? 0 : 100;
  return ((current - previous) / previous) * 100;
};

const Home = () => {
  const [weeksSessions, setWeeksSessions] = useState('Loading details...');
  const [percentageChange, setPercentageChange] = useState(null);

  const [nextSession, setNextSession] = useState('Loading details...');
  const [nextSessionDetails, setNextSessionDetails] = useState('Loading details...');

  const [todaysAppointments, setTodaysAppointments] = useState([]);

  const [clients, setClients] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const sessionCount = async () => {
      const today = new Date();
    
      // Calculate the start and end of the week
      const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      startOfWeek.setHours(0, 0, 0, 0);
    
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      endOfWeek.setHours(23, 59, 59, 999);

      // Query to count the number of documents matching the filters
      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('coachId', '==', auth.currentUser.uid),
        where('appointmentDateTime', '>=', startOfWeek),
        where('appointmentDateTime', '<=', endOfWeek),
        where('isCancelled', '==', false),
        where('isDeleted', '==', false)
      );
    
      // Use the count() aggregation query
      const snapshot = await getCountFromServer(q);
      const thisWeekCount = snapshot.data().count;
      setWeeksSessions(thisWeekCount);

      // Calculate the start and end of last week
      const startOfLastWeek = new Date(startOfWeek);
      startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);
      const endOfLastWeek = new Date(endOfWeek);
      endOfLastWeek.setDate(endOfLastWeek.getDate() - 7);

      // Query to count the number of documents for last week
      const lastWeekQuery = query(
        appointmentsRef,
        where('coachId', '==', auth.currentUser.uid),
        where('appointmentDateTime', '>=', startOfLastWeek),
        where('appointmentDateTime', '<=', endOfLastWeek),
        where('isCancelled', '==', false)
      );

      const lastWeekSnapshot = await getCountFromServer(lastWeekQuery);
      const lastWeekCount = lastWeekSnapshot.data().count;

      // Calculate percentage change
      const change = calculatePercentageChange(lastWeekCount, thisWeekCount);
      setPercentageChange(change);
    };

    sessionCount();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      const coachId = auth.currentUser.uid;
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('coachUid', '==', coachId), where('role', '==', 'client'), orderBy('firstName'));
      
      try {
        const querySnapshot = await getDocs(q);
        const clients = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setClients(clients);
        return clients;;
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
    };

    const fetchTodaysAppointments = async (clients) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('coachId', '==', auth.currentUser.uid),
        where('appointmentDateTime', '>=', today),
        where('appointmentDateTime', '<', tomorrow),
        where('isCancelled', '==', false),
        orderBy('appointmentDateTime', 'asc')
      );

      try {
        const querySnapshot = await getDocs(q);
        const appointments = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const appointmentData = doc.data();
          const statusIcon = await getStatusIcon(doc.id);
          const client = clients.find(client => client.id === appointmentData.clientId);
          
          const clientName = client ? `${client.firstName} ${client.lastName}` : 'Unknown Client';
          return {
            id: doc.id,
            ...appointmentData,
            statusIcon,
            clientName
          };
        }));

        console.log("appointments", appointments)

        setTodaysAppointments(appointments);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching today's appointments: ", error);
        setLoading(false);
      }
    };

    const fetchData = async () => {
      const clients = await fetchClients();
      await fetchTodaysAppointments(clients);
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchNextSession = async () => {
      const now = new Date();
      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('coachId', '==', auth.currentUser.uid),
        where('appointmentDateTime', '>', now),
        where('isCancelled', '==', false),
        orderBy('appointmentDateTime', 'asc'),
        limit(1)
      );

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const nextAppointment = querySnapshot.docs[0].data();
          const client = clients.find(client => client.id === nextAppointment.clientId);
          const appointmentDateTime = new Date(nextAppointment.appointmentDateTime.seconds * 1000);
          const hours = appointmentDateTime.getHours();
          const minutes = appointmentDateTime.getMinutes();
          const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

          let detailsText = nextAppointment.portal_notes
            ? `${nextAppointment.portal_notes} with ${client.firstName} ${client.lastName}`
            : `With ${client.firstName} ${client.lastName}`;

          const clientCount = nextAppointment.clientIds ? nextAppointment.clientIds.length : 1;
          if (clientCount > 1) {
            detailsText += ` + ${clientCount - 1}`;
          }

          setNextSession(formattedTime);
          setNextSessionDetails(detailsText);
        } else {
          setNextSession("No upcoming sessions");
          setNextSessionDetails("Maybe book a session?");
        }
      } catch (error) {
        console.error("Error fetching next session: ", error);
      }
    };

    if (clients.length > 0) {
      fetchNextSession();
    }
  }, [clients]);

  const handleCreateAppointment = async () => {
    if (!selectedClient) return;

    const client = clients.find(client => client.id === selectedClient);
    const appointmentData = {
      notes: '',
    };

    const appointmentDateTime = new Date();

    try {
      const newAppointment = await createAppointment(client.id, auth.currentUser.uid, 'private', appointmentData, appointmentDateTime);
      console.log("newAppointment", newAppointment);
      console.log(client);
      setShowModal(false);
      navigate(`/dashboard/clients/${client.id}/start-session/${newAppointment}`);
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusIcon = async (appointmentId) => {
    try {
      const completedWorkoutDoc = await getDoc(doc(db, 'completed_workouts', appointmentId));
      const completionStatus = completedWorkoutDoc.exists() ? completedWorkoutDoc.data().completion_status : undefined;

      if (completionStatus === undefined) {
        return "none";
      } else if (completionStatus === false) {
        return "pending";
      } else {
        return "complete";
      }
    } catch (error) {
      console.error("Error fetching completed workout:", error);
      return "none";
    }
  };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />

      {/* Main content */}
      <div className="h-screen flex-grow-1 d-flex flex-column overflow-y-lg-auto">

        <Header title="👋 Welcome!" />
        {/* Header */}
  

        {/* Main */}
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">

            {/* Card stats */}
            <div className="row g-6 mb-6">
              <div className="col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Weeks Sessions</span>
                        <span className="h3 font-bold mb-0">{weeksSessions  !== null ? weeksSessions : "Loading..."}</span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                          <i className="bi bi-calendar"></i>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 mb-0 text-sm">
                    <span className={`badge badge-pill bg-opacity-30 ${percentageChange >= 0 ? 'bg-success text-success' : 'bg-danger text-danger'} me-2`}>
                      <i className={`bi bi-arrow-${percentageChange >= 0 ? 'up' : 'down'} me-1`}></i>
                      {Math.abs(percentageChange).toFixed(2)}%
                    </span>
                      <span className="text-nowrap text-xs text-muted">Since last week</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Next Session</span>
                        <span className="h3 font-bold mb-0">{nextSession ? nextSession : "No upcoming sessions"}</span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                          <i className="bi bi-clock-history"></i>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 mb-0 text-sm">
                      <span className="badge badge-pill bg-opacity-30 bg-danger text-danger me-2">
                        {/* <i className="bi bi-arrow-down me-1"></i>-5% */}
                      </span>
                      <span className="text-nowrap text-xs text-muted">
                        {nextSessionDetails ? nextSessionDetails : "No additional details available"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Upcoming Appointments */}
            <div className="card mb-7">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Today's Sessions</h5>
                <button className="btn btn-primary btn-sm" onClick={() => setShowModal(true)}>Quickstart new appointment</button>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Time</th>
                      <th scope="col">Location</th>
                      <th scope="col">Type</th>
                      <th scope="col">
                        <i className="bi bi-check-square"></i>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="6" className="text-center">Loading...</td>
                      </tr>
                    ) : (
                      todaysAppointments.length > 0 ? (
                        todaysAppointments.map((appointment, index) => (
                          <tr key={index}>
                            <td>{appointment.clientName}</td>
                            <td>
                                <i className={`${getTimeIcon(new Date(appointment.appointmentDateTime.toDate()))}`}></i>
                              {appointment.appointmentDateTime.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                            </td>
                            <td>
                              <i className={`${getLocationIcon(appointment.location_type)} me-2`}></i>
                              {appointment.location_type.charAt(0).toUpperCase() + appointment.location_type.slice(1)} {/* Capitalize the location type */} 
                            </td>
                            <td>
                              <i className={`${getTypeIcon(appointment.type)} me-2`}></i>
                              {appointment.type.charAt(0).toUpperCase() + appointment.type.slice(1)}
                            </td>
                            <td>
                              {appointment.statusIcon === "none" && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`tooltip-none-${appointment.id}`}>Workout Not Started</Tooltip>}
                                >
                                  <i className="bi bi-circle text-muted ms-2" title="Workout Not Started" />
                                </OverlayTrigger>
                              )}
                              {appointment.statusIcon === "pending" && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`tooltip-pending-${appointment.id}`}>Workout Started</Tooltip>}
                                >
                                  <i className="bi bi-check-circle-fill text-warning ms-2" title="Workout Started" />
                                </OverlayTrigger>
                              )}
                              {appointment.statusIcon === "complete" && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`tooltip-complete-${appointment.id}`}>Workout Complete</Tooltip>}
                                >
                                  <i className="bi bi-check-circle-fill text-success ms-2" title="Workout Completed" />
                                </OverlayTrigger>
                              )}
                            </td>
                            <td className="text-end">
                              <a href={`http://localhost:3000/dashboard/clients/${appointment.clientId}/start-session/${appointment.id}`} className="btn btn-sm btn-neutral">
                                View
                              </a>
                              <button
                                type="button"
                                className="btn btn-sm btn-square btn-neutral text-danger-hover"
                              >
                                <i className="bi bi-trash" />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No appointments scheduled for today.</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>


          </div>
        </main>


      </div>
    

    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Quickstart New Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Client Name</Form.Label>
            <Form.Control
              as="select"
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
            >
              <option value="">Select a client</option>
              {clients.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(client => (
                <option key={client.id} value={client.id}>
                  {client.firstName} {client.lastName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCreateAppointment}>
          Create Appointment
        </Button>
      </Modal.Footer>
    </Modal>

    </div>
  );
};

export default Home;