import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase/firebase';

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Failed to login', error);
      throw error;
    }
  };

  const googleSignIn = async () => {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({
      // Only request the email from Google (no immediate sign-in)
      prompt: 'select_account',
    });
  
    try {
      // Open Google sign-in popup, but don't create an account yet.
      const auth = getAuth();
      await signInWithPopup(auth, googleProvider);

    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };


  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Failed to logout', error);
    }
  };

  return { user, isLoading, login, googleSignIn, logout };
};
