import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp, collection, query, where, getDocs, addDoc, orderBy } from 'firebase/firestore';
import Sidebar from '../../layouts/Coachportal_sidebar';
import Header from '../../layouts/Header';
import WorkoutDetailsForm from '../../components/coachportal/workoutDetailsForm';
import { fetchExercises } from '../../utils/exerciseUtils';
import { getAuth } from 'firebase/auth';
import ExerciseTable from '../../components/shared/ExerciseTable';
import { useIsSmallScreen } from '../../hooks/useWindowWidth';

const SessionStart = () => {
  const { workoutId, id: clientId } = useParams();

  const [workout, setWorkout] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completedWorkoutId, setCompletedWorkoutId] = useState(null);
  const [completedWorkoutDetails, setCompletedWorkoutDetails] = useState({
    workoutName: '',
    duration: '',
    difficulty: '',
    dateAssigned: '',
    notes: ''
  });
  const [exercises, setExercises] = useState({});
  
  const [exerciseOptions, setExerciseOptions] = useState([]);
  const [defaultExercises, setDefaultExercises] = useState([]);

  const isSmallScreen = useIsSmallScreen();

  const navigate = useNavigate();
  const auth = getAuth();
  const coachId = auth.currentUser.uid;

  // Fetch exercises from Firestore
  useEffect(() => {
    const loadExercises = async () => {
      const exercises = await fetchExercises(coachId);  // Use the utility function
      setExerciseOptions(exercises);

      // Store exercise names in default_exercises
      const exerciseNames = exercises.map(ex => ex.label);
      setDefaultExercises(exerciseNames);
    };

    loadExercises();
  }, [coachId]);
  
  // Fetch appointment and workout data
  useEffect(() => {
    const fetchAppointmentAndWorkout = async () => {
      setIsLoading(true);
      try {
        const appointmentDocRef = doc(db, 'appointments', workoutId);
        const appointmentDocSnap = await getDoc(appointmentDocRef);
        console.log(appointmentDocSnap);
        if (appointmentDocSnap.exists()) {
          const appointmentData = appointmentDocSnap.data();

          // Check if there's an existing completed workout
          const completedWorkoutDocRef = doc(db, 'completed_workouts', workoutId);
          const completedWorkoutSnap = await getDoc(completedWorkoutDocRef);

          console.log(completedWorkoutSnap);

          if (completedWorkoutSnap.exists()) {
            const completedWorkoutData = completedWorkoutSnap.data();
            setWorkout(completedWorkoutData);
            setCompletedWorkoutDetails({
              workoutName: completedWorkoutData.name || '',
              duration: completedWorkoutData.duration || '',
              difficulty: completedWorkoutData.difficulty || '',
              dateAssigned: completedWorkoutData.date_assigned || '',
              notes: completedWorkoutData.notes || ''
            });
            setCompletedWorkoutId(workoutId);
          } else {
            // Create a new completed workout document
            const completedWorkoutsRef = collection(db, 'completed_workouts');
            const newCompletedWorkoutRef = doc(completedWorkoutsRef, workoutId);
            
            const newWorkoutData = {
              parentId: workoutId,
              clientId: clientId,
              completion_status: false,
              completion_timestamp: null,
              created_at: serverTimestamp(),
              name: appointmentData.portal_notes,
              type: "coach_live_session",
              duration: appointmentData.duration || '',
              difficulty: appointmentData.difficulty || '',
              notes: ''
            };

            await setDoc(newCompletedWorkoutRef, newWorkoutData);

            setWorkout(newWorkoutData);
            setCompletedWorkoutDetails({
              workoutName: newWorkoutData.name || '',
              duration: newWorkoutData.duration || '',
              difficulty: newWorkoutData.difficulty || '',
              dateAssigned: newWorkoutData.date_assigned || '',
              notes: newWorkoutData.notes || ''
            });
            setCompletedWorkoutId(workoutId);
          }

          // Fetch exercises from client_exercise_logs
          const exerciseLogsRef = collection(db, 'client_exercise_logs');
          const q = query(
            exerciseLogsRef,
            where('workout_id', '==', workoutId),
            where('deleted_at', '==', null),
            orderBy('orderNumber', 'asc')
          );
          const exerciseLogsSnapshot = await getDocs(q);
          const exercisesData = {};
          exerciseLogsSnapshot.forEach((doc) => {
            const exerciseData = doc.data();
            exercisesData[exerciseData.exercise_name] = exerciseData;
          });
          setExercises(exercisesData);

          console.log(exercisesData);

        } else {
          setError("Appointment not found");
        }

      } catch (error) {
        console.error("Error fetching appointment and workout:", error);
        setError("Failed to load appointment and workout. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAppointmentAndWorkout();
  }, [workoutId, clientId]);

  const handleFinishWorkout = async () => {
    try {
      const completedWorkoutRef = doc(db, 'completed_workouts', completedWorkoutId);

      await updateDoc(completedWorkoutRef, {
        completion_status: true,
        completion_timestamp: serverTimestamp(),
        name: completedWorkoutDetails.workoutName,
        duration: completedWorkoutDetails.duration,
        difficulty: completedWorkoutDetails.difficulty,
        date_assigned: completedWorkoutDetails.dateAssigned,
        exercises: exercises,
        notes: workout.notes || null
      });

      navigate(`/dashboard/clients/${clientId}?tab=Schedule`);
    } catch (error) {
      console.error("Error updating workout progress:", error);
      setError("Failed to save workout progress. Please try again.");
    }
  };

  const handleCreateExercise = async (inputValue, index) => {
    try {
      const newExerciseRef = await addDoc(collection(db, "users", coachId, "exercises"), { name: inputValue });
      const newOption = { label: inputValue, value: newExerciseRef.id };
      setExerciseOptions(prevOptions => [...prevOptions, newOption]);
      handleExerciseChange(index, 'name', newOption.label, newOption);
      return newOption;
    } catch (error) {
      console.error("Error creating new exercise: ", error);
      throw error;
    }
  };

  const handleExerciseChange = (index, field, value, selectedOption) => {
    const newExercises = {...exercises};
    if (field === 'name' && selectedOption) {
      newExercises[value] = {
        name: value,
        sets: selectedOption.defaultSets || [],
        reps: selectedOption.defaultUnits || '',
        unitType: selectedOption.unitType || '',
        weight: selectedOption.defaultWeight || '', 
      };
    } else if (newExercises[Object.keys(newExercises)[index]]) {
      newExercises[Object.keys(newExercises)[index]][field] = value;
    }
    setExercises(newExercises);
  };

  if (isLoading) {
    return <div className="container mt-4">Loading workout data...</div>;
  }

  if (error) {
    return <div className="container mt-4 text-danger">{error}</div>;
  }

  if (!workout) {
    return <div className="container mt-4">No workout data available.</div>;
  }

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Sidebar />
        <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-lg-auto">
            <Header title={`Start Session`} />
                <div className="container-fluid">
                    <WorkoutDetailsForm
                        completedWorkoutDetails={completedWorkoutDetails}
                        setCompletedWorkoutDetails={setCompletedWorkoutDetails}
                        setExercises={setExercises}
                        coachId={auth.currentUser.uid}
                        type="coach_live_session"
                        defaultExercises={defaultExercises}
                        exerciseOptions={exerciseOptions}
                        workoutId={workoutId} // Pass workoutId to WorkoutDetailsForm
                        workoutCollection="completed_workouts"
                    />
                    <div className={`${isSmallScreen ? 'ms-3 mt-3' : 'card m-4'} rounded-0`}>
                        <div className="card-body">
                            <ExerciseTable 
                                workout={exercises}
                                setWorkout={setExercises}
                                isEditable={true}
                                workoutId={workoutId}
                                clientId={clientId}
                                exerciseOrder={Object.keys(exercises)}
                                handleComplete={handleFinishWorkout}
                                exerciseOptions={exerciseOptions}
                                handleCreateExercise={handleCreateExercise}
                                coachId={coachId}
                            />
                        </div>
                    </div>
                </div>
        </div>
    </div>
  );
};

export default SessionStart;