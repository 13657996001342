import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { ProtectedRoute } from './utils/ProtectedRoute';
// Analytics
import { useEffect } from 'react';
import { initializeAnalytics, trackPageView } from './utils/analytics'; // Import the functions
// Landing PAges
import SignUpPage from './pages/landingPage/SignUpPage';
import HomePage from './pages/landingPage/HomePage';
import LoginPage from './pages/landingPage/LoginPage';
// Coach pages
import Home from './pages/coachportal/Home';
import Clients from './pages/coachportal/ClientList';
import ClientDetails from './pages/coachportal/ClientDetails';
import Account from './pages/coachportal/Account';
import ProgramGroupsList from './pages/coachportal/ProgramsList';
import GroupsDetails from './pages/coachportal/ProgramsDetails';
import CoachChat from './pages/coachportal/Chat';
import CreateWorkout from './pages/coachportal/WorkoutCreate';
import EditWorkout from './pages/coachportal/WorkoutEdit';
import SessionStart from './pages/coachportal/SessionStart';
// Client pages
import ClientHome from './pages/clientportal/Home';
import Scheduling from './pages/clientportal/Schedule';
import Program from './pages/clientportal/Program';
import Chat from './pages/clientportal/Chat';
import ClientAccount from './pages/clientportal/AccountSettings';
import WorkoutPreview from './pages/clientportal/WorkoutPreview';
import WorkoutStart from './pages/clientportal/WorkoutStart';


import { Capacitor } from '@capacitor/core';
import MobileHome from './pages/landingPage/MobileHome';
import { StatusBar } from '@capacitor/status-bar'; 


// import the styles
import './scss/main.scss';
import './scss/utility.scss';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    initializeAnalytics(); // Initialize Google Analytics once
    trackPageView(location.pathname); // Track the initial page load
  }, [location.pathname]);

  useEffect(() => {
      trackPageView(location.pathname); // Track page views on route changes
  }, [location]);

  return null;
}

function App() {
  const { isLoading } = useAuth();

  useEffect(() => {
    const adjustForStatusBar = async () => {
      if (Capacitor.isNativePlatform()) {
        await StatusBar.show();
        const rootElement = document.getElementById('root');
        if (rootElement) {
          const platform = Capacitor.getPlatform();


          if (platform === 'ios') {
            rootElement.style.paddingTop = '44px';
          } else if (platform === 'android') {
            rootElement.style.paddingTop = '20px';
          } else {
            rootElement.style.paddingTop = '44px';
          }
          // rootElement.style.paddingTop = '70px';
        }
      }
    };

    adjustForStatusBar();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <AnalyticsTracker />
      <Routes>
        {/* Landing pages */}
        <Route path="/" element={Capacitor.isNativePlatform() ? <MobileHome /> : <HomePage />} />
        <Route path="/test" element={<MobileHome />} />
        <Route path="/register" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* Client pages */}
        <Route path="/website/:username/dashboard" element={<ClientHome />} />
        <Route path="/website/:username/scheduling" element={<Scheduling />} />
        <Route path="/website/:username/program" element={<Program />} />
        <Route path="/website/:username/account" element={<ClientAccount />} />
        <Route path="/website/:username/chat" element={<Chat />} />
        <Route path="/website/:username/workout-preview/:workoutId" element={<ProtectedRoute><WorkoutPreview /></ProtectedRoute>} />
        <Route path="/website/:username/workout-start/:workoutId" element={<ProtectedRoute><WorkoutStart /></ProtectedRoute>} />
        {/* Coach pages */}
        <Route path="/dashboard" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/dashboard/clients" element={<ProtectedRoute><Clients /></ProtectedRoute>} />
        <Route path="dashboard/clients/:id" element={<ProtectedRoute><ClientDetails /></ProtectedRoute>} />
        <Route path="/dashboard/groups" element={<ProtectedRoute><ProgramGroupsList /></ProtectedRoute>} />
        <Route path="/dashboard/groups/:groupId" element={<ProtectedRoute><GroupsDetails /></ProtectedRoute>} />
        <Route path="/dashboard/account/profile" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path="/dashboard/chat" element={<ProtectedRoute><CoachChat /></ProtectedRoute>} />
        <Route path="/dashboard/groups/:groupId/create-workout" element={<ProtectedRoute><CreateWorkout type="group" /></ProtectedRoute>}  />
        <Route path="/dashboard/groups/:groupId/edit-workout/:workoutId" element={<ProtectedRoute><EditWorkout type="group" /></ProtectedRoute>} />
        <Route path="/dashboard/clients/:id/create-workout" element={<ProtectedRoute><CreateWorkout type="personalized" /></ProtectedRoute>}  />
        <Route path="/dashboard/clients/:id/edit-workout/:workoutId" element={<ProtectedRoute><EditWorkout type="personalized" /></ProtectedRoute>} />
        <Route path="/dashboard/clients/:id/start-session/:workoutId" element={<SessionStart />} />
      </Routes>
    </Router>
  );
}

export default App;
