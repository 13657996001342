import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { getFirestore, doc, setDoc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import Select from 'react-select'; // Import react-select
import logo_dark from '../../assets/logo_dark.png';
import Home from '../../assets/app_photos/Home.png'; // Import Home.png
import Start from '../../assets/app_photos/Start_workout.png'; // Import Home.png
import { useNavigate } from 'react-router-dom';
import {useIsSmallScreen} from '../../hooks/useWindowWidth';

const db = getFirestore();

function HomePage() {
  const [email, setEmail] = useState("");
  const [coaches, setCoaches] = useState([]);
  const { user } = useAuth();
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [showCoachModal, setShowCoachModal] = useState(false);
  const [userRedirect, setUserRedirect] = useState(null);

  const isSmallScreen = useIsSmallScreen();

  useEffect(() => {
    // Fetch list of coaches when component mounts
    const fetchCoaches = async () => {
      const q = query(collection(db, "users"), where("role", "==", "coach"));
      const querySnapshot = await getDocs(q);

      const coachList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        // Check if username exists, publicProfile is true, and at least one of firstName or lastName is present
        if (data.username && data.publicProfile === true && (data.firstName || data.lastName)) {
          let label = data.username;
          
          if (data.firstName || data.lastName) {
            label = `${data.username} (${data.firstName || ''} ${data.lastName || ''})`.trim();
          }
          
          coachList.push({ 
            value: doc.id, 
            label: label.trim(), // Ensure there is no trailing space
            username: data.username // Add username to the coach object
          });
        }
      });
      setCoaches(coachList);
    };

    fetchCoaches();
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const docRef = doc(db, "waitlist", email);
      await setDoc(docRef, { email: email });
      console.log("Document written with ID: ", docRef.id);
      
      alert('Success! Your email has been added to the waitlist.');
      setEmail(""); // Reset the email field
      return <Navigate to="/" replace />; // Navigate back to homepage
      
      // TODO: send confirmation email here
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const navigate = useNavigate();

  const handleCoachSelect = (selectedCoach) => {
    if (selectedCoach && selectedCoach.value) {
      const coachUsername = selectedCoach.username; // Use the username directly from the selected coach object
      navigate(`/login?coachUsername=${coachUsername}`); // Navigate to the login page with coachUsername as a parameter
    }
  };

  useEffect(() => {
    const checkUserRole = async () => {
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.role === 'coach') {
              setUserRedirect(<Navigate to="/dashboard" replace />);
            } else if (userData.role === 'client') {
              // Fetch coach data
              const coachRef = doc(db, 'users', userData.coachUid);
              const coachSnap = await getDoc(coachRef);
              
              if (coachSnap.exists()) {
                const coachData = coachSnap.data();
                setUserRedirect(<Navigate to={`/website/${coachData.username}/dashboard`} replace />);
              } else {
                console.error("Coach document doesn't exist");
                // Handle the case where coach data is not found
              }
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    checkUserRole();
  }, [user]);

  if (userRedirect) {
    return userRedirect;
  }

  return (
    <div>
      <div>
        <nav className="navbar navbar-expand navbar-light px-0 py-3">
          <div className="container-xl max-w-screen-xl">
            {/* Logo */}
            <a className="navbar-brand" href="/">
              <img
                src={logo_dark}
                className="h-11"
                alt="Logo"
              />
            </a>
            <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={() => setShowCoachModal(true)}>
                    Find Your Coach
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <hr className="my-0" />

        <div className="py-10 position-relative"> {/* Reduced padding from py-md-32 to py-md-16 */}
          <div className="container-lg max-w-screen-xl">
            <div className="row align-items-center">
              <div className="col-lg-6 order-md-0">
                {/* Surtitle */}
                <h5 className="h5 mb-5 text-uppercase text-warning mb-5">
                  Built with love. powered by AI
                </h5>
                {/* Heading */}
                <h1 className="ls-tight font-bolder display-3 mb-5">
                  Time to level up your coaching business
                </h1>
                {/* Text */}
                <p className="lead mb-10">
                  Design and launch a personalized app, complete with AI powered workout generations, appointment & payment management, in app messaging, and more.
                </p>
                {/* Buttons */}
                <div className="d-flex justify-content-center mx-n2">
                  <button className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8" onClick={() => setShowWaitlistModal(true)}>
                    Get started
                  </button>
                </div>
              </div>
              <div className="col-lg-6 order-lg-1 ms-auto">
                <div className="mb-5 mb-lg-0 w-11/10 position-relative">
                  {/* iPhone Frame with Placeholder */}
                  <div className="svg-fluid position-relative overlap-10">
                    <img
                      src={Home}
                      alt="iPhone Screenshot Placeholder"
                      className="img-fluid"
                      style={{
                        width: "225px",
                        height: "488px",
                        borderRadius: "30px",
                        backgroundColor: "#fff",
                        display: "block",
                        margin: "100px auto 0",
                        boxShadow: "0 0 0 10px #000"
                      }}
                    />
                    {/* Second iPhone */}
                    <img
                      src={Start}
                      alt="iPhone Screenshot Placeholder 2"
                      className="img-fluid position-absolute"
                      style={{
                        width: "225px",
                        height: "488px",
                        borderRadius: "30px",
                        backgroundColor: "#fff",
                        boxShadow: "0 0 0 10px #000",
                        top: isSmallScreen ? "-50px" : "-50px", // Adjusted for overlap on mobile
                        left: isSmallScreen ? "20px" : "420px", // Adjusted for overlap on mobile
                        zIndex: -1,
                      }}
                    />

                  </div>

                  
                  {/* Decorations */}
                  <div className="position-absolute bottom-0 start-72 h-64 w-64 mt-n4 transform translate-y-n1/2 translate-x-n1/2 gradient-bottom-right start-purple-400 end-cyan-500 filter blur-100 rounded-4 p-5">
                    {" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="bg-dark pt-32 pb-10">
          <div className="container max-w-screen-xl">
            {/* Section title */}
            <div className="row justify-content-center text-center mb-20">
              <div className="col-lg-7">
                {/* Surtitle */}
                <h5 className="h5 mb-5 text-uppercase text-warning">Features</h5>
                {/* Heading */}
                <h1 className="ls-tight font-bolder text-white mb-5">
                  Are you ready to manage your business better?
                </h1>
                {/* Text */}
                <p className="lead text-white text-opacity-75">
                  Our suite of functionality allows you to automate the less fun stuff, while delivering a better experience for your clients. 
                </p>
              </div>
            </div>
            {/* Content */}
            <div className=" position-relative overlap-10 mt-32">
              <div className="row g-12">
                <div className="col-md-4">
                  <div className="card mb-lg-n40 shadow-6 border-0">
                    <div className="card-body p-10">
                      {/* Illustration */}
                      <div className="svg-fluid h-40 pt-7 position-relative overlap-10">
                        <svg
                          id="afcd27bc-9e58-484b-acf0-96ac0ca03cce"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width={888}
                          height="677.20705"
                          viewBox="0 0 888 677.20705"
                        >
                          <polygon
                            points="307.693 659.535 316.417 662.148 330.648 629.74 317.772 625.884 307.693 659.535"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M462.32048,767.41626l17.18156,5.1462.00069.00021a11.43063,11.43063,0,0,1,7.66935,14.22912l-.10659.35581-28.13113-8.4259Z"
                            transform="translate(-156 -111.39648)"
                            fill="#2f2e41"
                          />
                          <polygon
                            points="390.602 666.663 399.153 663.528 391.13 629.055 378.51 633.682 390.602 666.663"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M543.39769,776.06729l16.83948-6.17423.00068-.00025a11.43063,11.43063,0,0,1,14.6658,6.79715l.12785.34873L547.46038,787.1476Z"
                            transform="translate(-156 -111.39648)"
                            fill="#2f2e41"
                          />
                          <polygon
                            points="322.321 600.868 307.464 649.897 325.292 655.097 342.378 609.782 322.321 600.868"
                            fill="#2f2e41"
                          />
                          <polygon
                            points="369.121 612.011 382.493 658.068 401.064 649.154 387.693 606.068 369.121 612.011"
                            fill="#2f2e41"
                          />
                          <path
                            d="M518.16217,731.71429a203.9796,203.9796,0,0,1-35.45752-3.27034l-.298-.06021v-11.3301l-6.78986-.75447,5.29979-18.92774c-2.47016-29.16024,1.03993-66.08934,2.17563-76.71865.26025-2.49265.43146-3.89132.43146-3.89132l5.95867-50.64955,9.97024-9.20308,4.52936,2.94751,8.40181,8.40145c9.766,24.0342,17.51548,46.661,17.56426,48.11483l32.75141,104.95321-.2209.156C551.10618,729.50964,533.77044,731.71429,518.16217,731.71429Z"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <polygon
                            points="337.708 477.181 335.418 491.475 350.921 497.87 337.708 477.181"
                            opacity="0.2"
                          />
                          <path
                            d="M524.12819,556.834H488.28426a2.78413,2.78413,0,0,1-2.781-2.781v-15.45a20.703,20.703,0,0,1,41.40592,0v15.45A2.78412,2.78412,0,0,1,524.12819,556.834Z"
                            transform="translate(-156 -111.39648)"
                            fill="#2f2e41"
                          />
                          <circle
                            cx="354.16363"
                            cy="429.32816"
                            r="15.17868"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M531.807,540.148H509.88932l-.22481-3.14673-1.12374,3.14673h-3.37485l-.4454-6.23672-2.22727,6.23672h-6.53018v-.309a16.39537,16.39537,0,0,1,16.37682-16.377H515.43a16.39549,16.39549,0,0,1,16.377,16.377Z"
                            transform="translate(-156 -111.39648)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M509.70994,559.718a2.84058,2.84058,0,0,1-.49216-.04345l-16.04911-2.83169V530.31841h17.667l-.4374.51c-6.08554,7.09733-1.50079,18.60574,1.77373,24.834a2.73982,2.73982,0,0,1-.21772,2.90894A2.76982,2.76982,0,0,1,509.70994,559.718Z"
                            transform="translate(-156 -111.39648)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M538.02851,623.82967h-8.79718a1.1313,1.1313,0,0,1-1.13-1.02492l-1.7609-18.04893h14.579l-1.7609,18.04871A1.13131,1.13131,0,0,1,538.02851,623.82967Z"
                            transform="translate(-156 -111.39648)"
                            fill="#5c60f5"
                          />
                          <path
                            d="M540.89615,607.02652H526.36369a1.13668,1.13668,0,0,1-1.13534-1.13535v-2.72484a1.13668,1.13668,0,0,1,1.13534-1.13534h14.53246a1.13668,1.13668,0,0,1,1.13535,1.13534v2.72484A1.13669,1.13669,0,0,1,540.89615,607.02652Z"
                            transform="translate(-156 -111.39648)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M483.8924,616.06358l0,0a27.88129,27.88129,0,0,0,33.46807,6.76478l3.30359-1.635Z"
                            transform="translate(-156 -111.39648)"
                            opacity="0.2"
                          />
                          <path
                            d="M536.71057,612.5695a6.96579,6.96579,0,0,0-10.67635.32242l-15.32558-4.30242-4.88606,8.67563,21.728,5.77187a7.00355,7.00355,0,0,0,9.16-10.4675Z"
                            transform="translate(-156 -111.39648)"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M500.13852,622.19727c-7.28644.00072-17.14532-4.27146-28.84884-12.546a5.73089,5.73089,0,0,1-2.4134-3.92759c-.86328-5.46918,4.4715-12.863,4.99455-13.57174l5.60811-15.40061c.06456-.25028,1.87184-6.91355,6.409-9.28432a7.43841,7.43841,0,0,1,6.21386-.26479c8.6423,3.147,1.894,27.44817.96757,30.63508l11.45016,5.38938,7.27119,4.6349,9.95773,1.04175-2.70358,12.51186-15.12275.34024A15.43364,15.43364,0,0,1,500.13852,622.19727Z"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M1043.67468,508.05561A98.57951,98.57951,0,1,0,854.454,546.865c-.09637-.10718-.196-.21149-.29193-.31909a98.66626,98.66626,0,0,0,17.95428,27.78283c.0224.02466.04541.04871.06788.07337.60559.66,1.21466,1.31659,1.83782,1.9599a98.28006,98.28006,0,0,0,69.52936,30.25354l-3.33105,180.9292h10.29126l-2.08283-119.41541,14.88678-7.83758-2.27093-4.31354-12.711,6.692-.97791-56.06378A98.57812,98.57812,0,0,0,1043.67468,508.05561Z"
                            transform="translate(-156 -111.39648)"
                            fill="#e6e6e6"
                          />
                          <path
                            d="M751.0871,460.00819a115.52648,115.52648,0,1,0-221.74982,45.4812c-.113-.12561-.22968-.24784-.3421-.37393a115.62807,115.62807,0,0,0,21.04083,32.559c.02625.02893.05322.05706.07959.086.70972.7735,1.42346,1.543,2.15375,2.29682a115.17561,115.17561,0,0,0,81.48224,35.45447l-3.90375,212.033h12.06042l-2.44091-139.94428,17.446-9.185-2.66138-5.05505-14.89618,7.84247-1.146-65.70178A115.52494,115.52494,0,0,0,751.0871,460.00819Z"
                            transform="translate(-156 -111.39648)"
                            fill="#e6e6e6"
                          />
                          <path
                            d="M419.25891,414.8147A131.46683,131.46683,0,1,0,166.912,466.57142c-.12855-.14295-.26138-.282-.38931-.42551a131.58166,131.58166,0,0,0,23.944,37.05149c.02989.03289.06059.065.09055.09787.80763.88021,1.61988,1.75582,2.45091,2.61373a131.0676,131.0676,0,0,0,92.72516,40.3465L281.291,787.54475h13.72454L292.23788,628.291,312.091,617.83869l-3.02856-5.75256-16.95154,8.92456-1.30411-74.76727A131.465,131.465,0,0,0,419.25891,414.8147Z"
                            transform="translate(-156 -111.39648)"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="756.68549"
                            cy="85.97574"
                            r="85.97575"
                            fill="#ff6584"
                          />
                          <circle
                            cx="245.55884"
                            cy="187.61628"
                            r="172.3117"
                            fill="#5c60f5"
                          />
                          <path
                            d="M274.32944,183.92207A172.32513,172.32513,0,0,0,561.45166,366.29173,172.32654,172.32654,0,1,1,274.32944,183.92207Z"
                            transform="translate(-156 -111.39648)"
                            opacity="0.2"
                            style={{ isolation: "isolate" }}
                          />
                          <polygon
                            points="246.032 187.616 246.506 187.616 255.027 676.148 237.038 676.148 246.032 187.616"
                            fill="#3f3d56"
                          />
                          <rect
                            x="401.08538"
                            y="564.58106"
                            width="32.19013"
                            height="8.5209"
                            transform="translate(-372.96557 148.44822) rotate(-27.76587)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M665.11523,782.975s.62171-13.02673,13.3664-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <circle
                            cx="505.51413"
                            cy="652.80328"
                            r="6.37865"
                            fill="#5c60f5"
                          />
                          <rect
                            x="504.47623"
                            y="663.54456"
                            width="1.80054"
                            height="12.60376"
                            fill="#3f3d56"
                          />
                          <path
                            d="M223.08289,781.17445s.62168-13.02674,13.36641-11.51258"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <circle
                            cx="63.48181"
                            cy="651.00275"
                            r="6.37865"
                            fill="#5c60f5"
                          />
                          <rect
                            x="62.44391"
                            y="661.74402"
                            width="1.80054"
                            height="12.60376"
                            fill="#3f3d56"
                          />
                          <path
                            d="M327.51416,782.07471s.6217-13.02673,13.36642-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <circle
                            cx="167.91309"
                            cy="651.90302"
                            r="6.37865"
                            fill="#5c60f5"
                          />
                          <rect
                            x="166.87518"
                            y="662.64429"
                            width="1.80054"
                            height="12.60376"
                            fill="#3f3d56"
                          />
                          <path
                            d="M605.24329,194.695l12.79486-10.23341c-9.93976-1.09662-14.02381,4.3243-15.69525,8.615-7.76532-3.22446-16.21882,1.00135-16.21882,1.00135l25.6001,9.29375A19.37211,19.37211,0,0,0,605.24329,194.695Z"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M799.827,298.93629l12.7948-10.23343c-9.93976-1.09662-14.02381,4.32431-15.69526,8.615-7.76532-3.22446-16.21881,1.00134-16.21881,1.00134l25.6001,9.29376A19.37241,19.37241,0,0,0,799.827,298.93629Z"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M589.95459,387.88883l12.7948-10.23343c-9.93976-1.09662-14.0238,4.32431-15.69525,8.615-7.76532-3.22445-16.21881,1.00138-16.21881,1.00138l25.60009,9.29373A19.37244,19.37244,0,0,0,589.95459,387.88883Z"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M839.65527,787.70325s.62171-13.02673,13.3664-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M719.91943,787.70325s.62171-13.02673,13.3664-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M283.2887,787.70325s.6217-13.02673,13.36642-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M893.67145,787.70325s.6217-13.02673,13.36639-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M868.46387,787.70325s.6217-13.02673,13.36639-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M816.465,787.70325s-.62171-13.02673-13.3664-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M609.40308,787.70325s-.62171-13.02673-13.3664-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M437.4516,787.70325s-.6217-13.02673-13.36643-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M254.69688,787.70325s-.6217-13.02673-13.36641-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M947.90442,787.70325s-.62171-13.02673-13.3664-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M870.4812,788.60352s-.6217-13.02673-13.36639-11.51257"
                            transform="translate(-156 -111.39648)"
                            fill="#3f3d56"
                          />
                          <rect y="674.60352" width={888} height={2} fill="#3f3d56" />
                        </svg>
                      </div>
                      {/* Title */}
                      <h5 className="h4 mt-16 mb-3">Automate your Business</h5>
                      {/* Text */}
                      <p>
                        Automate things like appointment reminders, schedling, payments - so you can focus on what you do best: Fitness. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mb-lg-n40 shadow-6 border-0">
                    <div className="card-body p-10">
                      {/* Illustration */}
                      <div className="svg-fluid h-40 pt-7 position-relative overlap-10">
                        <svg
                          id="b578e6b2-033c-40ac-a2d2-0bd8957fb38d"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="1005.31822"
                          height={465}
                          viewBox="0 0 1005.31822 465"
                        >
                          <rect
                            y="384.58641"
                            width="981.69583"
                            height="2.47715"
                            fill="#cbcbcb"
                          />
                          <path
                            d="M1102.65911,411.04688c0,85.182-50.63935,114.92434-113.10611,114.92434S876.44689,496.22883,876.44689,411.04688,989.553,217.5,989.553,217.5,1102.65911,325.8649,1102.65911,411.04688Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#f1f1f1"
                          />
                          <polygon
                            points="888.092 295.441 889.251 224.152 937.459 135.956 889.432 212.968 889.952 180.913 923.178 117.105 890.091 172.43 890.091 172.43 891.027 114.779 926.605 63.98 891.174 105.713 891.759 0 888.082 139.945 888.384 134.172 852.211 78.803 887.803 145.255 884.434 209.642 884.333 207.933 842.632 149.666 884.207 213.97 883.785 222.023 883.71 222.143 883.744 222.804 875.193 386.16 886.618 386.16 887.989 301.783 929.461 237.637 888.092 295.441"
                            fill="#e5e5e5"
                          />
                          <path
                            d="M346.69505,221.41052v4.28269H229.37228A67.90322,67.90322,0,0,0,161.469,293.59635V542.16067a67.90324,67.90324,0,0,0,67.903,67.90344H873.01413a67.90324,67.90324,0,0,0,67.90331-67.90317V293.59669a67.9032,67.9032,0,0,0-67.903-67.90345h-442.807v-4.28268Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M229.84605,240.14612H872.541a50.71258,50.71258,0,0,1,50.71172,50.71171V540.61448A50.71264,50.71264,0,0,1,872.541,591.32619H229.84605a50.7127,50.7127,0,0,1-50.71171-50.71171V510.31589a24.10455,24.10455,0,0,0,33.1902-22.30884V345.60723a24.10462,24.10462,0,0,0-33.1902-22.30888V290.8578A50.71261,50.71261,0,0,1,229.84605,240.14612Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#fff"
                          />
                          <circle
                            cx="321.07084"
                            cy="138.65862"
                            r="51.58827"
                            fill="#ff6583"
                          />
                          <path
                            d="M642.23444,591.92679H293.02482a7.12487,7.12487,0,0,1-1.305-.10338l165.16007-286.081a11.56407,11.56407,0,0,1,20.12981,0l110.84316,191.9825,5.3103,9.18641Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#5c60f5"
                          />
                          <polygon
                            points="544.893 374.426 425.665 374.426 484.194 289.411 488.406 283.287 490.512 280.225 495.822 289.411 544.893 374.426"
                            opacity="0.2"
                            style={{ isolation: "isolate" }}
                          />
                          <path
                            d="M836.67186,591.92679H534.6342l58.529-85.01549,4.21193-6.12432L673.6436,389.9955c5.00018-7.2612,17.02891-7.71335,22.90762-1.36954a13.78648,13.78648,0,0,1,1.09829,1.36954Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#5c60f5"
                          />
                          <path
                            d="M251.15678,460.99618a10.74269,10.74269,0,0,1,3.06875-16.18426l23.222-95.04913,16.94053,5.4078L269.31231,449.571a10.80091,10.80091,0,0,1-18.15553,11.42515Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#9f616a"
                          />
                          <polygon
                            points="165.818 182.699 178.198 193.379 195.519 208.309 203.168 169.169 203.968 165.099 204.599 161.869 187.048 131.299 172.418 132.139 171.418 132.199 171.398 132.419 171.168 134.469 168.168 161.449 168.098 162.099 165.818 182.699"
                            fill="#cbcbcb"
                          />
                          <polygon
                            points="246.737 452.373 258.997 452.373 264.829 405.085 246.735 405.086 246.737 452.373"
                            fill="#9f616a"
                          />
                          <path
                            d="M340.95063,665.87054l24.144-.001h.001a15.38731,15.38731,0,0,1,15.38648,15.38624v.5l-39.53076.00146Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#2f2e41"
                          />
                          <polygon
                            points="118.737 451.373 130.997 451.373 136.829 404.085 118.735 404.086 118.737 451.373"
                            fill="#9f616a"
                          />
                          <path
                            d="M212.95063,664.87054l24.14405-.001h.001a15.38731,15.38731,0,0,1,15.38648,15.38624v.5l-39.53076.00146Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M206.27937,648.049l33,5,48.00977-162.48,50.99023,163.48,37-5-48-190v-15l-6.5-22.5.68018-6.09,4.73-42.59,2.04981-18.47.13285-1.19313a4.0555,4.0555,0,0,0-3.19291-4.41685v0l-15.39991-3.24H284.60945l-14.8501,4.09-1.02.28-.46.13.23,1.92,3.48,29.18.18994,1.6,3.3601,28.12994.74,6.17c-29,21-30,42-30,42Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#cbcbcb"
                          />
                          <circle
                            cx="202.89518"
                            cy="90.83636"
                            r="24.56103"
                            fill="#9f616a"
                          />
                          <rect
                            x="273.77937"
                            y="414.54895"
                            width={49}
                            height={7}
                            transform="matrix(-0.99156, -0.12967, 0.12967, -0.99156, 442.48992, 653.74694)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M388.197,419.85133a10.52534,10.52534,0,0,0-1.31643,1.00712l-48.05635-12.2506-3.27839-11.59339-18.14315,2.66087,3.80921,21.08783a8,8,0,0,0,7.03474,6.53394l56.11129,5.90907a10.49709,10.49709,0,1,0,3.83908-13.35484Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#9f616a"
                          />
                          <path
                            d="M314.27937,355.049l8.5-6.5c2.29817-.87171,5.44731,1.605,6,4l12,49-25,18-3.5-5.5Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#cbcbcb"
                          />
                          <g opacity="0.2">
                            <path
                              d="M265.50935,378.94892a67.67834,67.67834,0,0,0,6.48,2.2c.7.21,1.41992.41,2.12988.6a66.01843,66.01843,0,0,0,14.34033,2.31994,45.1399,45.1399,0,0,0,6.25977-.17,35.24193,35.24193,0,0,0,6.58984-1.3c.07031-.02.15039-.04.22021-.06a28.90608,28.90608,0,0,0,11.9502-6.79,23.55972,23.55972,0,0,0,2.10986-2.35,21.2388,21.2388,0,0,0,4.56006-16.53,14.15326,14.15326,0,0,0-.34033-1.57,25.10742,25.10742,0,0,0-1.48975-4.1c-.27-.62-.57031-1.23-.89013-1.83l-11.29981-3.82h-21.52l-14.8501,4.09-1,.06-.02.22-.46.13.23,1.92Z"
                              transform="translate(-97.34089 -217.5)"
                            />
                          </g>
                          <path
                            d="M319.05645,288.00236c4.59329-.56305,9.634-.16485,13.21411,2.76739s4.73558,9.0278,1.34592,12.17829c-3.00083,2.78909-7.69329,2.13322-11.71262,2.92632a15.85417,15.85417,0,0,0-12.38363,15.15656c.05982,10.17413,9.82411,17.879,11.578,27.90094,1.197,6.83982-1.68772,14.03818-6.675,18.86965s-11.84323,7.44256-18.7509,8.1498-13.8926-.37294-20.60575-2.14766c-6.74127-1.78215-13.3778-4.31054-19.03424-8.388s-10.2931-9.82559-12.10928-16.55777-.51227-14.45088,4.16774-19.61986c5.35905-5.919,14.27436-7.95446,18.90989-14.45564,5.19639-7.28779,3.27352-17.66626,7.4792-25.56731,2.80791-5.27513,8.12457-8.87314,13.79191-10.76857s11.719-2.26954,17.69212-2.45311c3.13956-.09649,6.37527-.12862,9.30035,1.016s5.49633,3.75317,5.648,6.89055S319.05645,288.00236,319.05645,288.00236Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M150.78679,682.5h277a1,1,0,1,0,0-2h-277a1,1,0,0,0,0,2Z"
                            transform="translate(-97.34089 -217.5)"
                            fill="#cbcbcb"
                          />
                        </svg>
                      </div>
                      {/* Title */}
                      <h5 className="h4 mt-16 mb-3">An App that Looks like You</h5>
                      {/* Text */}
                      <p>
                        Personalize an app for your clients, complete with chat, schedules, and daily workouts. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mb-lg-n40 shadow-6 border-0">
                    <div className="card-body p-10">
                      {/* Illustration */}
                      <div className="svg-fluid h-40 pt-7 position-relative overlap-10">
                        <svg
                          id="ee5053fc-ea24-484a-861d-c80bd2ae1a91"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="982.48732"
                          height="763.01413"
                          viewBox="0 0 982.48732 763.01413"
                        >
                          <circle
                            cx="559.4329"
                            cy="333.06116"
                            r="40.02979"
                            fill="#e6e6e6"
                          />
                          <path
                            d="M766.295,452.99465l-41.832-41.832a44.307,44.307,0,0,0-14.42627-61.92578,44.45758,44.45758,0,0,0-58.91358,12.23779,44.30652,44.30652,0,0,0,60.03321,62.99512l41.832,41.832A9.40939,9.40939,0,0,0,766.295,452.99465ZM709.59431,409.6011a31.68421,31.68421,0,1,1,9.27978-22.40381A31.58568,31.58568,0,0,1,709.59431,409.6011Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M1069.529,514.69884C1176.50914,633.00725,866.39184,803.50707,615.8095,803.50707S191.86334,671.39983,162.09,514.69884C89.89987,134.75174,777.16735-106.74054,615.8095,225.89055,380.61181,710.73882,985.27984,421.5284,1069.529,514.69884Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#e6e6e6"
                          />
                          <path
                            d="M1021.529,542.69884C1128.50914,661.00725,818.39184,831.50707,567.8095,831.50707S143.86334,699.39983,114.09,542.69884C41.89987,162.75174,729.16735-78.74054,567.8095,253.89055,332.61181,738.73882,937.27984,449.5284,1021.529,542.69884Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M258.35026,265.31823c-75.06741-3.30525-133.27133-19.49788-132.50668-36.86436.45844-10.41178,21.70324-18.7842,58.28677-22.96955a1,1,0,1,1,.22727,1.987c-34.46168,3.943-56.11733,12.01678-56.516,21.07051-.69148,15.70451,59.11433,31.63093,130.59659,34.77832s132.4564-7.461,133.14787-23.16554c.40032-9.09178-20.59681-19.06558-54.79775-26.0284a1.00008,1.00008,0,1,1,.3991-1.95993c36.3011,7.39058,56.85694,17.624,56.39672,28.0763C392.81953,257.60908,333.41767,268.62348,258.35026,265.31823Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#5c60f5"
                          />
                          <path
                            d="M191.67927,268.22672a76.98277,76.98277,0,0,0,133.03578,5.52178A1211.97551,1211.97551,0,0,1,191.67927,268.22672Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#5c60f5"
                          />
                          <path
                            d="M333.019,252.71347a76.997,76.997,0,1,0-147.74945-5.70562A572.23325,572.23325,0,0,0,333.019,252.71347Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#5c60f5"
                          />
                          <circle cx="168.83353" cy="123.60073" r={9} fill="#fff" />
                          <circle cx="122.51347" cy="152.59128" r={15} fill="#fff" />
                          <circle
                            cx="348.90479"
                            cy="142.89813"
                            r="9.99975"
                            fill="#5c60f5"
                          />
                          <circle
                            cx="122.90479"
                            cy="452.89813"
                            r="6.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="542.90479"
                            cy="658.89813"
                            r="6.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="151.90479"
                            cy="339.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="399.90479"
                            cy="563.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="850.90479"
                            cy="534.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="709.90479"
                            cy="623.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="562.90479"
                            cy="515.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="372.90479"
                            cy="219.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="396.90479"
                            cy="71.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="382.90479"
                            cy="694.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="234.90479"
                            cy="631.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle
                            cx="703.90479"
                            cy="523.89813"
                            r="3.00007"
                            fill="#e6e6e6"
                          />
                          <circle cx="148.3737" cy="224.7987" r={9} fill="#fff" />
                          <path
                            d="M460.6612,431.39114c0,26.88035-41.55939,89.516-53.70465,107.24669a3.99188,3.99188,0,0,1-6.5907,0c-12.14526-17.73066-53.70465-80.36634-53.70465-107.24669a57,57,0,1,1,114,0Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#fff"
                          />
                          <circle cx="294.90486" cy="358.8982" r={29} fill="#5c60f5" />
                          <ellipse
                            cx="294.40486"
                            cy="496.3982"
                            rx="33.5"
                            ry="6.5"
                            fill="#fff"
                          />
                          <path
                            d="M925.60957,386.41245a10.52681,10.52681,0,0,0-.64106,1.52851l-47.783,13.27682-8.61085-8.42662L854.1589,404.12429,867.953,420.52338a8,8,0,0,0,9.35283,2.169l51.61632-22.78484a10.49709,10.49709,0,1,0-3.31256-13.49509Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M875.50191,401.996l-17.9352,13.95958a4.5,4.5,0,0,1-6.68163-1.33719L839.73843,394.891a12.49741,12.49741,0,0,1,19.76923-15.29426l16.33819,15.59379a4.5,4.5,0,0,1-.34394,6.80543Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#5c60f5"
                          />
                          <path
                            d="M930.19158,379.80967l18.18807,8.1829a3.14913,3.14913,0,0,1,1.57976,4.16384l-2.15339,4.78633a1.05293,1.05293,0,0,1,.52659,1.38795l-.86136,1.91453a1.05292,1.05292,0,0,1-1.388.52659l-.86136,1.91453a1.05292,1.05292,0,0,1,.52659,1.388l-.86136,1.91453a1.0529,1.0529,0,0,1-1.38794.52659L931.8709,432.36162a3.14914,3.14914,0,0,1-4.16384,1.57977l-18.18807-8.1829a3.14915,3.14915,0,0,1-1.57977-4.16384l18.08852-40.20522A3.14913,3.14913,0,0,1,930.19158,379.80967Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#3f3d56"
                          />
                          <path
                            d="M912.82137,423.21181l14.59056,6.57106a3.03424,3.03424,0,0,0,3.99937-1.51155l6.73892-14.98951,2.34078-5.196,6.09875-13.562a3.026,3.026,0,0,0-1.51149-3.98886l-4.58726-2.06786L930.47694,383.964a3.02112,3.02112,0,0,0-3.98861,1.522L920.589,398.59669l-6.7179,14.92653-2.56118,5.69979A3.022,3.022,0,0,0,912.82137,423.21181Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#f2f2f2"
                          />
                          <polygon
                            points="773.577 540.443 785.837 540.442 791.67 493.154 773.575 493.155 773.577 540.443"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M879.2066,604.93325l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00146Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#2f2e41"
                          />
                          <polygon
                            points="805.577 537.443 817.837 537.442 823.67 490.154 805.575 490.155 805.577 537.443"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M911.2066,601.93325l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00146Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M827.34138,497.97109a39.41035,39.41035,0,0,0,36.8999,26.81006l.62012.01c6.32959.18,13.75976-1.86,21.0498-4.79,14.3999-5.79,28.23-15.06,31.8999-17.61l-6.21972,59.79-2.52,24.24a4.51415,4.51415,0,0,0,4.48,4.97h15.52978a4.50547,4.50547,0,0,0,4.35987-3.36l25.64013-97.65a19.032,19.032,0,0,0-19.71-23.83l-53.25976,3.65,3.71972-14.88-47.54-3.16-.13965.1c-1.14013.83-2.25,1.7-3.31005,2.61a40.27149,40.27149,0,0,0-6.99024,7.66A38.48741,38.48741,0,0,0,827.34138,497.97109Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M827.34138,497.97109a39.41035,39.41035,0,0,0,36.8999,26.81006,196.14623,196.14623,0,0,0,22.56982-13.39l-.8999,8.61-7.83984,75.42a4.51415,4.51415,0,0,0,4.48,4.97h15.52978a4.50547,4.50547,0,0,0,4.35987-3.36l9.15039-34.85,16.48974-62.8a19.032,19.032,0,0,0-19.71-23.83l-53.25976,3.65,3.71972-14.88-26.98-1.79A38.48741,38.48741,0,0,0,827.34138,497.97109Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#2f2e41"
                          />
                          <path
                            d="M838.7735,458.32082l-.35766-.043-8.29517-40.78515c-.071-.354-6.85229-35.5332,13.93677-53.92432l.36182-2.51709a4.50032,4.50032,0,0,1,5.78711-3.65771l19.58471,6.0747a4.49086,4.49086,0,0,1,3.019,5.4419l-1.90381,7.2417c2.63989,3.1001,33.7146,40.51221,22.89038,69.33545L888.9488,464.439Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#5c60f5"
                          />
                          <path
                            d="M920.09389,426.48986a10.52591,10.52591,0,0,0-1.32908.99037l-47.89739-12.858-3.13136-11.634-18.17538,2.43095,3.54192,21.13437a8,8,0,0,0,6.95146,6.62248l56.032,6.619a10.4971,10.4971,0,1,0,4.00785-13.30516Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M869.022,414.43646,846.48068,417.34a4.5,4.5,0,0,1-5.07405-4.54822l.42987-22.65461a12.49741,12.49741,0,0,1,24.79917-3.12052l6.14215,21.73423a4.5,4.5,0,0,1-3.75583,5.68559Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#5c60f5"
                          />
                          <circle
                            cx="753.92061"
                            cy="258.80372"
                            r="24.56103"
                            fill="#ffb8b8"
                          />
                          <path
                            d="M861.3848,329.34791l-3.075-5.59966c-5.82461,23.73215,4.46383,43.37315,4.46383,43.37315l-39.93243-18.601.38033-6.59991-3.95134,4.484-5.67747-3.12872-.74992-4.28064-4.28563,1.06158,15.64354-28.38736c15.18238-27.01314,37.25508-16.69982,37.25508-16.69982,35.11544-1.66934,30.82681,32.97543,30.82681,32.97543Z"
                            transform="translate(-108.75634 -68.49293)"
                            fill="#2f2e41"
                          />
                          <ellipse
                            cx="556.1612"
                            cy="472.39114"
                            rx="12.17949"
                            ry="47.5"
                            transform="translate(-275.75171 306.36236) rotate(-32.66242)"
                            fill="#3f3d56"
                          />
                        </svg>
                      </div>
                      {/* Title */}
                      <h5 className="h4 mt-16 mb-3">AI Clone = Passive Income</h5>
                      {/* Text */}
                      <p>
                        Offer budget clients an AI version of you: complete with programs, chat, and videos of you. All with just a few clicks.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="pt-40 pb-24 bg-surface-secondary">
          <div className="container max-w-screen-xl">
            {/* Section title */}
            <div className="row justify-content-center text-center mb-20">
              <div className="col-lg-6">
                {/* Title */}
                <h1 className="ls-tight font-bolder display-5 mb-4">
                  Affordable Pricing
                </h1>
                {/* Text */}
                <p className="lead px-lg-20">
                  An expansive free tier, with options for more as you grow.
                </p>
              </div>
            </div>
            {/* Content */}
            <div className="row g-10 g-lg-0 align-items-center justify-content-center">
              
              <div className="col-lg-4">
                <div className="p-1 bg-card border border-card rounded-4 shadow-5 position-relative transform scale-lg-110 overlap-10">
                  <div className="card card-pricing bg-dark">
                    {/* Card header */}
                    <div className="pt-10 px-7 px-md-12">
                      {/* Badge */}
                      <span className="d-inline-block w-20 px-2 py-1 bg-success text-center text-xs text-white font-semibold rounded-pill position-absolute top-0 end-0 mt-4 me-4">
                        Popular
                      </span>
                      {/* Plan name */}
                      <h3 className="text-primary font-semibold">Free</h3>
                      {/* Price */}
                      <div className="display-4 text-white d-flex my-4">
                        <div>
                          $<span>0</span>
                        </div>
                        <div className="text-white text-opacity-75 text-base font-semibold align-self-end ms-2">
                          <span className="d-block mt-n8"> / month</span>
                        </div>
                      </div>
                      {/* Text */}
                      <p className="text-white text-opacity-75 text-sm">
                        Forever Free, with an expansive suite of functionality
                      </p>
                    </div>
                    {/* Delimiter */}
                    <div className="px-md-12 pt-6 pb-6">
                      <hr className="m-0 opacity-10" />
                    </div>
                    {/* Card body */}
                    <div className="px-md-12 px-5 pb-4">
                      {/* List */}
                      <ul className="list-unstyled">
                        <li className="py-1 d-flex align-items-center">
                          {/* Icon */}
                          <div>
                            <span className="text-xl text-success me-3 svg-icon svg-align-baseline">
                              <i className="bi bi-check" />
                            </span>
                          </div>
                          {/* Text */}
                          <p className="text-white text-opacity-75 text-sm font-semibold">
                            All key functionality, including a personalized app, AI programming, and payment/scheduling automation. 
                          </p>
                        </li>
                        <li className="py-1 d-flex align-items-center">
                          {/* Icon */}
                          <div>
                            <span className="text-xl text-success me-3 svg-icon svg-align-baseline">
                              <i className="bi bi-check" />
                            </span>
                          </div>
                          {/* Text */}
                          <p className="text-white text-opacity-75 text-sm font-semibold">
                            Unlimited Clients (with the use of payments)
                          </p>
                        </li>
                        <li className="py-1 d-flex align-items-center">
                          {/* Icon */}
                          <div>
                            <span className="text-xl text-success me-3 svg-icon svg-align-baseline">
                              <i className="bi bi-check" />
                            </span>
                          </div>
                          {/* Text */}
                          <p className="text-white text-opacity-75 text-sm font-semibold">
                            Flat 4% Payment Processing Fee 
                          </p>
                        </li>
                      </ul>
                      {/* Button */}
                      <a href="#" className="btn btn-lg w-full btn-primary mb-4">
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card card-pricing">
                  {/* Card header */}
                  <div className="pt-10 px-7 px-md-12">
                    {/* Plan name */}
                    <h3 className="text-dark font-semibold">Pro</h3>
                    {/* Price */}
                    <div className="display-4 text-heading d-flex my-4">
                      <div>
                        $<span className="">29</span>
                      </div>
                      <div className="text-muted text-base font-semibold align-self-end ms-2">
                        <span className="d-block mt-n8"> / month</span>
                      </div>
                    </div>
                    {/* Text */}
                    <p className="text-muted text-sm">
                      Expanded tier with additional functionality
                    </p>
                  </div>
                  {/* Delimiter */}
                  <div className="px-md-12 pt-6 pb-6">
                    <hr className="m-0" />
                  </div>
                  {/* Card body */}
                  <div className="px-md-12 px-5 pb-4">
                    {/* List */}
                    <ul className="list-unstyled">
                      <li className="py-1 d-flex align-items-center">
                        {/* Icon */}
                        <div>
                          <span className="text-xl text-success me-3 svg-icon svg-align-baseline">
                            <i className="bi bi-check" />
                          </span>
                        </div>
                        {/* Text */}
                        <p className="text-muted text-sm font-semibold">
                          All features in Free
                        </p>
                      </li>
                      <li className="py-1 d-flex align-items-center">
                        {/* Icon */}
                        <div>
                          <span className="text-xl text-success me-3 svg-icon svg-align-baseline">
                            <i className="bi bi-check" />
                          </span>
                        </div>
                        {/* Text */}
                        <p className="text-muted text-sm font-semibold">
                          AI Video Generation: Videos of you demonstrating workouts, generated by AI
                        </p>
                      </li>
                      <li className="py-1 d-flex align-items-center">
                        {/* Icon */}
                        <div>
                          <span className="text-xl text-success me-3 svg-icon svg-align-baseline">
                            <i className="bi bi-check" />
                          </span>
                        </div>
                        {/* Text */}
                        <p className="text-muted text-sm font-semibold">
                          Flat 3% Payment Processing Fee 
                        </p>
                      </li>
                    </ul>
                    {/* Button */}
                    <a href="#" className="btn btn-lg w-full btn-dark mb-4">
                      Get started
                    </a>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <footer className="py-20 bg-surface-dark text-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 text-center">
                <p className="text-sm text-white text-opacity-50">
                  © Copyright 2024 Coachcloud 
                </p>
              </div>
            </div>
          </div>
        </footer>





        <div className="pt-10 pt-md-16 bg-surface-dark overflow-hidden">
          <div className="container-lg max-w-screen-xl">

            {/* Waitlist Modal */}
            {showWaitlistModal && (
              <div className="modal show d-block" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Join Waitlist</h5>

                      <button type="button" className="btn-close" onClick={() => setShowWaitlistModal(false)} />
                    </div>
                    <div className="modal-body">
                      <p className="text-left mb-6">We're currently testing our app to make it perfect for you! Join the waitlist to be let in as more spots open.</p> {/* Aligned to the left and added buffer below */}
                      
                      <form onSubmit={handleFormSubmit}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-primary mt-3">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Coach Search Modal */}
            {showCoachModal && (
              <div className="modal show d-block" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Find Your Coach</h5>
                      <button type="button" className="btn-close" onClick={() => setShowCoachModal(false)} />
                    </div>
                    <div className="modal-body">
                      <Select
                        options={coaches}
                        onChange={handleCoachSelect}
                        placeholder="Search for a coach..."
                        isClearable
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

  );
}

export default HomePage;
