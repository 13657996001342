// src/components/shared/ExercisesTable.js
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { useIsSmallScreen } from '../../hooks/useWindowWidth';


// DropdownIndicator component to remove the default dropdown arrow
const DropdownIndicator = props => {
  return null;
};

const ExercisesTable = ({
  exercises,
  exerciseOptions,
  handleExerciseChange,
  handleCreateExercise,
  handleAddExercise,
  handleDeleteExercise,
  handleVideoIconClick,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const smallScreenPadding = '5px';
  const smallScreenMargin = '2px';
  const inputStyle = isSmallScreen ? { padding: '2px', margin: '1px' } : {};
  
  return (
    <div className={`${isSmallScreen ? 'ms-0 mt-10' : 'card m-4 ms-3'} rounded-0`}>
      <div className="card-header d-flex align-items-center">
        <h5 className="me-auto h3">Exercises</h5>
        <button type="button" onClick={handleAddExercise} className={`${isSmallScreen ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-primary'}`}>
          + Add Row
        </button>
      </div>

      <div className={`${isSmallScreen ? 'mt-4' : ''} table-responsive`}>
        <table className="table table-hover table-nowrap">
          <thead className="table-light">
            <tr>
              <th scope="col" className="d-none d-md-table-cell" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>#</th>
              <th scope="col" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>{isSmallScreen ? 'Ex. Name' : 'Exercise Name'}</th>
              <th scope="col" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin, minWidth: "60px" } : { minWidth: "105px" }}>Sets</th>
              <th scope="col" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin, minWidth: "60px" } : { minWidth: "105px" }}>Units</th>
              <th scope="col" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin, minWidth: "60px" } : {}}>{isSmallScreen ? 'Unit' : 'Unit Type'}</th>
              <th scope="col" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>{isSmallScreen ? 'Weight' : 'Weight (lbs)'}</th>
              <th scope="col" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}><i className="fa-regular fa-solid fa-video"></i></th>
              <th scope="col" className="d-none d-md-table-cell" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {exercises.map((exercise, index) => (
              <tr key={index}>
                <td className="d-none d-md-table-cell" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>{index + 1}</td>
                <td style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>
                  <CreatableSelect
                    isClearable
                    isSearchable
                    value={exercise.name ? { label: exercise.name, value: exercise.name } : null}
                    onChange={(selectedOption) =>
                      handleExerciseChange(
                        index,
                        'name',
                        selectedOption ? selectedOption.label : '',
                        selectedOption
                      )
                    }
                    options={exerciseOptions}
                    onCreateOption={(inputValue) => handleCreateExercise(inputValue, index)}
                    placeholder={isSmallScreen ? "Add ex." : "Add an exercise"}
                    components={{ DropdownIndicator }}
                    menuPortalTarget={document.body} // Attach the dropdown to the body of the page
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} // Custom style to make the dropdown appear outside of card
                  />
                </td>
                <td style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>
                  <input
                    type="number"
                    className="form-control"
                    style={inputStyle}
                    value={exercise.sets !== null ? exercise.sets : ''}
                    onChange={(e) => handleExerciseChange(index, 'sets', e.target.value)}
                  />
                </td>
                <td style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>
                  <input
                    type="number"
                    className="form-control"
                    style={inputStyle}
                    value={exercise.reps !== null ? exercise.reps : ''}
                    onChange={(e) => handleExerciseChange(index, 'reps', e.target.value)}
                    placeholder={
                      exercise.unitType === 'time'
                        ? 'Minutes'
                        : exercise.unitType === 'distance'
                        ? 'Meters'
                        : 'Reps'
                    }
                  />
                </td>
                <td style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>
                  <select
                    className="form-select"
                    style={inputStyle}
                    value={exercise.unitType}
                    onChange={(e) => handleExerciseChange(index, 'unitType', e.target.value)}
                  >
                    <option value="reps">Reps</option>
                    <option value="time">Time</option>
                    <option value="distance">Distance</option>
                  </select>
                </td>
                <td style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>
                  <input
                    type="number"
                    className="form-control"
                    style={inputStyle}
                    value={exercise.weight !== null ? exercise.weight : ''}
                    onChange={(e) => handleExerciseChange(index, 'weight', e.target.value)}
                  />
                </td>
                <td style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>
                  <i
                    className="fa-regular fa-clapperboard-play"
                    style={{
                      color: exercise.videoLink ? 'blue' : 'gray',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleVideoIconClick(index)}
                  />
                </td>
                <td className="d-none d-md-table-cell" style={isSmallScreen ? { padding: smallScreenPadding, margin: smallScreenMargin } : {}}>
                  <button
                    type="button"
                    onClick={() => handleDeleteExercise(index)}
                    className="btn btn-danger btn-sm"
                  >
                    X
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExercisesTable;
