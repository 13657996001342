// Home.js
import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import ClientNavbar from '../../layouts/ClientNavbar';
import { db } from '../../firebase/firebase';
import { collection, query, where, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Scheduling = () => {
  const { username } = useParams();
  const [appointments, setAppointments] = useState([]);
  const [filter, setFilter] = useState('Upcoming');

  useEffect(() => {
    const fetchData = async () => {
      const currentUserId = auth.currentUser.uid;

      try {
        const appointmentsRef = collection(db, 'appointments');
        const q = query(
          appointmentsRef,
          where('clientId', '==', currentUserId),
          where ('isDeleted', '==', false),
          orderBy('appointmentDateTime', 'asc')
        );
        const querySnapshot = await getDocs(q);

        const appointmentsWithCoachName = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
          const appointmentData = docSnapshot.data();
          let coachFirstName = await getCoachFirstName(appointmentData.coachId);
          coachFirstName = capitalizeFirstLetter(coachFirstName);

          return {
            id: docSnapshot.id,
            ...appointmentData,
            coachFirstName,
          };
        }));

        setAppointments(appointmentsWithCoachName);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };

    fetchData();
  }, []);

  const filterAppointments = (appointments, filter) => {
    return appointments.filter(appointment => {
      const appointmentDateTime = new Date(appointment.appointmentDateTime.seconds * 1000);
      const currentDate = new Date();

      switch (filter) {
        case 'Upcoming':
          return (appointmentDateTime > currentDate && !appointment.isCancelled);
        case 'Completed':
          return appointmentDateTime < currentDate && !appointment.isCancelled;
        case 'All active':
          return !appointment.isCancelled;
        case 'Cancelled':
          return appointment.isCancelled;
        default:
          return true;
      }
    });
  };

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  // Placeholder function - replace with actual logic
  const getCoachFirstName = async (coachId) => {
    if (!coachId) {
      console.error('No coach ID provided');
      return '';
    }

    try {
      const coachRef = doc(db, 'users', coachId);
      const coachSnap = await getDoc(coachRef);

      if (coachSnap.exists()) {
        const coachData = coachSnap.data();
        return coachData.firstName || ''; // Assuming the field is named 'firstName'
      } else {
        console.error('Coach not found');
        return '';
      }
    } catch (error) {
      console.error('Error fetching coach data:', error);
      return '';
    }
  };

  return (
    <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
      {/* Vertical Navbar */}
      <ClientNavbar username={username} />

      {/* Main content */}
      <div className="h-screen flex-grow-1 overflow-y-lg-auto">
        <header className="bg-surface-primary border-bottom pt-6 sticky-top">
          <div className="container-fluid">
            <div className="mb-npx">
              <div className="row align-items-center justify-content-between">
                <div className="col mb-4 mb-sm-0">
                  {/* Title */}
                  <h1 className="h2 mb-0 ls-tight">Schedule</h1>
                </div>

                {/* Dropdown */}
                <div className="col-auto">
                  <div className="dropdown">
                    <button className="btn btn-sm btn-neutral dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      {filter}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Upcoming'); }}>Upcoming</a></li>
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Completed'); }}>Completed</a></li>
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('All active'); }}>All active</a></li>
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Cancelled'); }}>Cancelled</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Nav */}
              <ul className="nav nav-tabs mt-4 overflow-x border-0"></ul>
            </div>
          </div>
        </header>

        <div className="card rounded-0">
          <div className="card-body">
            <div className="list-group list-group-flush">
              {filterAppointments(appointments, filter).length > 0 ? (
                filterAppointments(appointments, filter).map((appointment, index) => {
                  const appointmentDate = new Date(appointment.appointmentDateTime.seconds * 1000);

                  const dayOfWeek = appointmentDate.toLocaleDateString('en-US', { weekday: 'short' });
                  const dateFormatted = appointmentDate.toLocaleDateString();

                  // Capitalize appointment type and fetch coach's first name
                  const appointmentTypeCapitalized = capitalizeFirstLetter(appointment.type);

                  // Construct the title
                  const appointmentTitle = `${appointmentTypeCapitalized} Training with ${appointment.coachFirstName}`;

                  // Create and format Start Time
                  const startTimeFormatted = appointmentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                  // Create and format End Time (one hour later)
                  const endTime = new Date(appointmentDate);
                  endTime.setHours(endTime.getHours() + 1);
                  const endTimeFormatted = endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                  return (
                    <div key={index} className="list-group-item d-flex align-items-center px-0">
                      {/* Date Section */}
                      <div className="d-flex align-items-start me-4">
                        <div className="text-center" style={{ width: '80px' }}>
                          <span className="d-block h6 font-semibold">{dayOfWeek}</span>
                          <span className="d-block h6 font-semibold">{dateFormatted}</span>
                        </div>
                      </div>
                      {/* Appointment Details */}
                      <div className="flex-fill" style={{ borderLeft: '1px solid #ddd', paddingLeft: '20px' }}>
                        <span className="d-block text-sm text-muted mb-2">{appointment.portal_notes || ''}</span>
                        <a className="d-block h3 font-semibold mb-3">{appointmentTitle}</a>
                        <span className="d-block text-sm text-muted font-semibold mb-2">🕛&nbsp;&nbsp;  {startTimeFormatted} - {endTimeFormatted}</span>
                      </div>
                      {/* Dropdown Menu */}
                      {/* ... dropdown menu code ... */}
                    </div>
                  );
                })
              ) : (
                <div className="list-group-item d-flex align-items-center px-0">
                  <div className="flex-fill text-center">
                    <p>No upcoming appointments</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scheduling;
