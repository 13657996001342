// Schedule.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../../../../firebase/firebase';
import { cancelUserAppointment } from '../../../../utils/appointments';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Schedule = ({ refreshKey, setRefreshKey }) => {
    const { id } = useParams();
    const coachId = auth.currentUser.uid;
    const [filter, setFilter] = useState('Upcoming');
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
    };

    const fetchData = async () => {
        setIsLoading(true);

        try {
            const appointmentsRef = collection(db, 'appointments');
            const q = query(
                appointmentsRef, 
                where('clientId', '==', id),
                where('isDeleted', '==', false),
                orderBy('appointmentDateTime', 'asc')
            );
            const querySnapshot = await getDocs(q);
            
            let data = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
                const appointmentData = docSnapshot.data();
                const completedWorkoutDoc = await getDoc(doc(db, 'completed_workouts', docSnapshot.id));
                const completionStatus = completedWorkoutDoc.exists() ? completedWorkoutDoc.data().completion_status : undefined;
                const statusIcon = await getStatusIcon(docSnapshot.id);
                return {
                    id: docSnapshot.id,
                    ...appointmentData,
                    completionStatus,
                    statusIcon
                };
            }));

            console.log("data", data);
            setAppointments(data);
        } catch (err) {
            console.error(err);
            setError("Failed to fetch appointments");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const getStatusIcon = async (appointmentId) => {
        try {
            const completedWorkoutDoc = await getDoc(doc(db, 'completed_workouts', appointmentId));
            const completionStatus = completedWorkoutDoc.exists() ? completedWorkoutDoc.data().completion_status : undefined;

            if (completionStatus === undefined) {
                return "none";
            } else if (completionStatus === false) {
                return "pending";
            } else {
                return "complete";
            }
        } catch (error) {
            console.error("Error fetching completed workout:", error);
            return "none";
        }
    };

    // Integrated ClientAppointmentsTable Component
    const ClientAppointmentsTable = ({ userId, clientId, type, filter }) => {
        const filteredAppointments = React.useMemo(() => {
            const currentDate = new Date();
            currentDate.setSeconds(0, 0);

            switch(filter) {
                case "Upcoming":
                    return appointments.filter(appointment => 
                        appointment.appointmentDateTime.toDate() > currentDate &&
                        !appointment.isCancelled &&
                        appointment.appointmentDateTime
                    );
                case "Completed":
                    return appointments.filter(appointment => 
                        appointment.appointmentDateTime.toDate() < currentDate &&
                        !appointment.isCancelled &&
                        appointment.appointmentDateTime
                    );
                case "All active":
                    return appointments.filter(appointment => 
                        !appointment.isCancelled && 
                        appointment.appointmentDateTime
                    );
                case "Cancelled":
                    return appointments.filter(appointment => 
                        appointment.isCancelled
                    );
                default:
                    return appointments;
            }
        }, [filter, appointments]);

        const handleCancel = async (userId, appointment) => {
            const currentDate = new Date();
            currentDate.setSeconds(0, 0);

            try {
                await cancelUserAppointment(userId, appointment.id);
                setError(null);
                fetchData(); // Reload the page data after cancellation
            } catch (err) {
                console.error(err);
                setError("Failed to cancel the appointment.");
            }
        };

        const handleStart = (appointment) => {
            navigate(`/dashboard/clients/${clientId}/start-session/${appointment.id}`);
        };

        return (
            <div className="table-responsive">
                {error && <p className="text-danger">{error}</p>}
                <table className="table table-hover table-nowrap">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">Appointment Date</th>
                            <th scope="col">Appointment Time</th>
                            <th scope="col">Appointment Type</th>
                            <th scope="col">Session Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="6" className="text-center">Loading...</td>
                            </tr>
                        ) : filteredAppointments.length === 0 ? (
                            <tr>
                                <td colSpan="6" className="text-center">No appointments available</td>
                            </tr>
                        ) : (
                            filteredAppointments.map(appointment => (
                                <tr key={appointment.id}>
                                    <td>
                                        {appointment.appointmentDateTime.toDate().toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                    </td>
                                    <td>
                                        {appointment.appointmentDateTime.toDate().toLocaleTimeString('en-US', {
                                            hour: 'numeric',
                                            minute: '2-digit',
                                            hour12: true
                                        })}
                                    </td>
                                    <td>{appointment.type}</td>
                                    <td>
                                        {appointment.portal_notes === "" ? "No name listed" : appointment.portal_notes}
                                    </td>
                                    <td>
                                        {appointment.completionStatus}
                                        {appointment.statusIcon === "none" && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-none-${appointment.id}`}>Workout Not Started</Tooltip>}
                                            >
                                                <i className="bi bi-circle text-muted ms-2" title="Workout Not Started" />
                                            </OverlayTrigger>
                                        )}
                                        {appointment.statusIcon === "pending" && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-pending-${appointment.id}`}>Workout Started</Tooltip>}
                                            >
                                                <i className="bi bi-check-circle-fill text-warning ms-2" title="Workout Started" />
                                            </OverlayTrigger>
                                        )}
                                        {appointment.statusIcon === "complete" && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-complete-${appointment.id}`}>Workout Complete</Tooltip>}
                                            >
                                                <i className="bi bi-check-circle-fill text-success ms-2" title="Workout Completed" />
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                    <td>
                                        <button 
                                            className="btn btn-sm btn-danger me-2"
                                            onClick={() => handleCancel(userId, appointment)}
                                        >
                                            Cancel
                                        </button>
                                        <button 
                                            className="btn btn-sm btn-primary"
                                            onClick={() => handleStart(appointment)}
                                        >
                                            Start
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="">
            {/* Table Header */}
            <div className="card-header border-bottom d-flex align-items-center">
                <h5 className="me-auto h3">Sessions</h5>
                <div className="dropdown">
                    <button 
                        className="btn btn-sm btn-neutral dropdown-toggle" 
                        type="button" 
                        id="dropdownMenuButton" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                    >
                        {filter}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                            <a 
                                className="dropdown-item" 
                                href="#" 
                                onClick={(e) => { 
                                    e.preventDefault(); 
                                    handleFilterChange('Upcoming'); 
                                }}
                            >
                                Upcoming
                            </a>
                        </li>
                        <li>
                            <a 
                                className="dropdown-item" 
                                href="#" 
                                onClick={(e) => { 
                                    e.preventDefault(); 
                                    handleFilterChange('Completed'); 
                                }}
                            >
                                Completed
                            </a>
                        </li>
                        <li>
                            <a 
                                className="dropdown-item" 
                                href="#" 
                                onClick={(e) => { 
                                    e.preventDefault(); 
                                    handleFilterChange('All active'); 
                                }}
                            >
                                All active
                            </a>
                        </li>
                        <li>
                            <a 
                                className="dropdown-item" 
                                href="#" 
                                onClick={(e) => { 
                                    e.preventDefault(); 
                                    handleFilterChange('Cancelled'); 
                                }}
                            >
                                Cancelled
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Table Itself */}
            <ClientAppointmentsTable 
                key={refreshKey} 
                userId={coachId} 
                clientId={id} 
                type="client" 
                filter={filter} 
            />
        </div>
    );
};

export default Schedule;
