// src/hooks/useWindowWidth.js
import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const isClient = typeof window === 'object';

  function getWidth() {
    return isClient ? window.innerWidth : undefined;
  }

  const [windowWidth, setWindowWidth] = useState(getWidth);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowWidth(getWidth());
    }

    window.addEventListener('resize', handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowWidth;
}

export const useIsSmallScreen = () => {
  const windowWidth = useWindowWidth();
  return windowWidth < 768;
}
