import { doc, getDoc, runTransaction, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';

export const createAppointment = async (clientUserId, coachId, type, appointmentData, appointmentDateTime) => {
  const clientUserRef = doc(db, 'users', clientUserId);
  const coachUserRef = doc(db, 'users', coachId);

  const newAppointment = {
    clientId: clientUserId,
    coachId: coachId,
    duration: appointmentData.duration || "", 
    portal_notes: appointmentData.notes || "", 
    public_notes: appointmentData.public_notes || "", 
    location_type: appointmentData.location_type || "", 
    location_address: appointmentData.location_address || "", 
    type: type || "", 
    max_clients: appointmentData.max_clients || 1, // Default to 1 for individual classes
    isCancelled: false, // NotE: This ONLY captures coach cancellations - client cancellations will be captured in the clientIds.
    isDeleted: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    currentCount: 1,
    appointmentDateTime
  };
  
  try {
    let appointmentId;
    await runTransaction(db, async (transaction) => {
      const clientUserSnapshot = await transaction.get(clientUserRef);
      const coachUserSnapshot = await transaction.get(coachUserRef);

      if (!clientUserSnapshot.exists()) {
        throw new Error("Client User not found!");
      }

      if (!coachUserSnapshot.exists()) {
        throw new Error ("Coach User not found!");
      }

      // Add the new appointment to the appointments collection
      const appointmentRef = await addDoc(collection(db, 'appointments'), newAppointment);
      appointmentId = appointmentRef.id;

      if (!appointmentId) {
        throw new Error("Failed to create appointment!");
      }

      // Get the current list of appointments for the client and coach users
      const clientAppointments = clientUserSnapshot.data().appointments;
      const coachAppointments = coachUserSnapshot.data().appointments;

      // Add the new appointment to the users' lists
      clientAppointments.push(appointmentRef.id); 
      coachAppointments.push(appointmentRef.id);

      // Update the user's appointments list
      transaction.update(clientUserRef, { appointments: clientAppointments });
      transaction.update(coachUserRef, { appointments: coachAppointments });
    });
    return appointmentId;
  } catch (e) {
    const errorMessage = 'Unable to create appointment: ' + e.message;
    alert(errorMessage); // This will show the error in the browser
    throw new Error(errorMessage);
  }
}

export const cancelUserAppointment = async (userId, appointmentId) => {
  // Document references
  const appointmentRef = doc(db, 'appointments', appointmentId);
  const userRef = doc(db, 'users', userId);

  console.log("appointments.js cancel - userID", userId);
  console.log("appointments.js cancel - userRed", userRef);

  // Run the transaction
  try {
      await runTransaction(db, async (transaction) => {
          const appointmentDoc = await transaction.get(appointmentRef);
          const userDoc = await transaction.get(userRef);

          if (!appointmentDoc.exists() || !userDoc.exists()) {
              throw new Error("User or appointment not found!");
          }

          const appointmentData = appointmentDoc.data();
          const userType = userDoc.data().role;


          console.log("appointments.js cancel - coachId", appointmentData.coachId);

          // Coach cancellation
          if (userType === 'coach' && appointmentData.coachId === userId) {
              console.log("This scenario!")
              transaction.update(appointmentRef, { isCancelled: true });
          } 
          // Client cancellation
          else if (userType === 'client') {
              const clientIndex = appointmentData.clientIds.findIndex(client => client.id === userId);
              if (clientIndex > -1) {
                  // Update the specific client's 'isCancelled' status
                  const updatedClient = { ...appointmentData.clientIds[clientIndex], isCancelled: true };
                  // Create a new array with the updated client object
                  const updatedClientIds = [
                      ...appointmentData.clientIds.slice(0, clientIndex),
                      updatedClient,
                      ...appointmentData.clientIds.slice(clientIndex + 1)
                  ];
                  transaction.update(appointmentRef, { clientIds: updatedClientIds });
              }
          } else {
              throw new Error("User type not valid or user is not part of this appointment!");
          }
      });
  } catch (e) {
      console.log('Transaction failure:', e);
      alert('Transaction failure: ' + e.message); // This will show the error in the browser
  }
}
