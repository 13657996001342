import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/firebase'; // adjust path as necessary
import { doc, getDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';

const GroupPrograms = ({ groupId }) => {
  const navigate = useNavigate();
  const [workouts, setWorkouts] = useState([]);
  const [filter, setFilter] = useState('all'); // 'upcoming', 'past', 'all'

  useEffect(() => {
    const fetchWorkouts = async () => {
      const groupRef = doc(db, 'fitness_group', groupId);
      const groupDoc = await getDoc(groupRef);
      const workoutIds = groupDoc.data()?.workouts || [];

      if (workoutIds.length === 0) {
        // If the workoutIds array is empty, return early or set an empty array for workouts
        setWorkouts([]);
        return;
      }

      const q = query(collection(db, 'assigned_workouts'), where('__name__', 'in', workoutIds));
      const querySnapshot = await getDocs(q);
      const workoutsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        dateAssigned: doc.data().dateAssigned, // Convert Firestore Timestamp to Date
      }));

      setWorkouts(workoutsData);
    };

    fetchWorkouts();
  }, [groupId]);

  const handleCreateWorkout = () => {
    navigate(`/dashboard/groups/${groupId}/create-workout`);
  };

  const handleEditWorkout = (workoutId) => {
    navigate(`/dashboard/groups/${groupId}/edit-workout/${workoutId}`);
  };

  const handleDeleteWorkout = async (workoutId) => {
    await deleteDoc(doc(db, 'assigned_workouts', workoutId));
    // Refresh the list after deletion
    setWorkouts(prev => prev.filter(workout => workout.id !== workoutId));
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value); 
  };

  const filteredWorkouts = workouts.filter(workout => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const workoutDate = new Date(workout.dateAssigned + 'T00:00:01');
    console.log('today:', today);
    console.log('workoutDate:', workoutDate);
    if (filter === 'Upcoming') return workoutDate > today;
    if (filter === 'Completed') return workoutDate < today;
    return true; // 'All active'
  });

  return (
    <div className="">
      <div className="card-header border-bottom d-flex align-items-center">
        <h5 className="me-auto h3">Workouts</h5>

        <button className="ms-3" onClick={handleCreateWorkout}>
          + Add New Workout
        </button>

        <select className="btn btn-sm btn-neutral dropdown-toggle ms-3" onChange={handleFilterChange} value={filter}>
          <option value="All active">All Active</option>
          <option value="Upcoming">Upcoming</option>
          <option value="Completed">Completed</option>
        </select>

      </div>

      <div className="table-responsive">
        <table className="table table-hover table-nowrap">
          <thead className="table-light">
            <tr>
              <th scope="col">Workout Name</th>
              <th scope="col">Date Assigned</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredWorkouts.map(workout => (
              <tr key={workout.id}>
                <td>{workout.name.charAt(0).toUpperCase() + workout.name.slice(1)}</td>
                <td>{workout.dateAssigned}</td>
                <td>
                  <button onClick={() => handleEditWorkout(workout.id)}>Edit</button>
                  <button className="ms-2" onClick={() => handleDeleteWorkout(workout.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default GroupPrograms;
