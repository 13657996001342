// ExerciseTable.js
import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, where, orderBy, limit, doc, updateDoc, serverTimestamp, setDoc, getDoc, deleteField } from 'firebase/firestore';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { db } from '../../firebase/firebase';
import { generateExerciseLogId } from '../../utils/generateExerciseLogId';
import { useIsSmallScreen } from '../../hooks/useWindowWidth';

const ExerciseTable = ({
    workout,
    setWorkout,
    isEditable,
    exerciseOptions,
    DropdownIndicator,
    workoutId,
    clientId,
    exerciseOrder,
    handleComplete,
    coachId
}) => {
    const [previousExercises, setPreviousExercises] = useState({});
    const [expandedExercise, setExpandedExercise] = useState(null);
    const [lastSaved, setLastSaved] = useState(null);
    const [newExercise, setNewExercise] = useState(null);
    const [repType, setRepType] = useState('Reps'); // New state for rep type
    
    const isSmallScreen = useIsSmallScreen();
    const smallScreenPadding = '10px';
    const smallScreenMargin = '5px';

    // Fetch previous exercise data for a given list of exercises
    const fetchPreviousExercises = useCallback(async (exercises) => {
        const previousExercisesData = {};

        for (const exerciseName of exercises) {
            const exerciseLogsRef = collection(db, 'client_exercise_logs');
            const q = query(
                exerciseLogsRef,
                where('client_id', '==', clientId),
                where('exercise_name', '==', exerciseName),
                where('sets.1.completed', '==', true),
                where('deleted_at', '==', null),
                orderBy('created_at', 'desc'),
                limit(2)
            );

            try {
                const querySnapshot = await getDocs(q);
                // Iterate through the documents to find the first one that isn't the current workout
                for (const docSnapshot of querySnapshot.docs) {
                    const data = docSnapshot.data();
                    if (data.workout_id !== workoutId) {
                        previousExercisesData[exerciseName] = data;
                        break; // Exit loop once we find a valid previous exercise
                    }
                }
            } catch (error) {
                console.error('Error fetching previous exercises for', exerciseName, error);
            }
        }

        setPreviousExercises(previousExercisesData);
    }, [clientId, workoutId]);

    // Fetch previous exercises when component loads
    useEffect(() => {
        if (workout && Object.keys(workout).length > 0) {
            fetchPreviousExercises(Object.keys(workout));
        }
    }, [fetchPreviousExercises, workout]);

    // Retrieve data from the previous set for an exercise
    const getPreviousSetData = useCallback((exerciseName, setNumber) => {
        if (!previousExercises[exerciseName] || !previousExercises[exerciseName].sets[setNumber]) {
            return '-';
        }
        const prevSet = previousExercises[exerciseName].sets[setNumber];
        if (!prevSet || !prevSet.completed) {
            return '-';
        }
        if (prevSet.weight && prevSet.reps) {
            return `${prevSet.weight}lb x ${prevSet.reps}`;
        } else if (prevSet.weight) {
            return `${prevSet.weight}lb`;
        } else if (prevSet.reps) {
            return prevSet.reps;
        }
        return '-';
    }, [previousExercises]);

    // Toggle expand/collapse state for an exercise
    const handleExpandExercise = useCallback((index) => {
        setExpandedExercise(prevExpanded => prevExpanded === index ? null : index);
    }, []);

    // Open video link in a new tab
    const handleVideoLinkClick = useCallback((videoLink) => {
        if (videoLink.startsWith("http")) {
            window.open(videoLink, "_blank");
        } else {
            window.open(`http://${videoLink}`, "_blank");
        }
    }, []);

    const handleChange = useCallback(async (exerciseName, setNumber, field, value) => {
        setWorkout(prevWorkout => {
            const updatedWorkout = { ...prevWorkout };
            const exercise = updatedWorkout[exerciseName];
            const set = exercise.sets[setNumber];

            if (field === 'status') {
                set.completed = value === 'complete';
                set.completed_at = value === 'complete' ? new Date().toISOString() : null;
            } else {
                set[field] = value;
            }

            return updatedWorkout;
        });

        // Generate deterministic exerciseLogId
        const exerciseLogId = generateExerciseLogId(clientId, workoutId, exerciseName);
        const exerciseLogRef = doc(db, 'client_exercise_logs', exerciseLogId);
        
        try {
            if (field === 'status') {
                await updateDoc(exerciseLogRef, {
                    [`sets.${setNumber}.completed`]: value === 'complete',
                    [`sets.${setNumber}.completed_at`]: value === 'complete' ? serverTimestamp() : null,
                });
            } else {
                await updateDoc(exerciseLogRef, {
                    [`sets.${setNumber}.${field}`]: value,
                });
            }
            setLastSaved(new Date().toLocaleTimeString());
        } catch (error) {
            console.error('Error updating exercise log: ', error);
        }
    }, [clientId, workoutId, setWorkout]);

    const addExercise = () => {
        setNewExercise({ name: '', sets: { 1: { reps: '', weight: '', completed: false } }, isNew: true, isCollapsed: false });
    };

    const handleNewExerciseSelect = async (selectedOption, actionMeta) => {
        try {
            const exerciseName = selectedOption.label;
            const exerciseLogId = generateExerciseLogId(clientId, workoutId, exerciseName);
            const exerciseLogRef = doc(db, 'client_exercise_logs', exerciseLogId);

            // Check if the exercise already exists in the workout
            if (workout[exerciseName]) {
                alert(`Exercise "${exerciseName}" already exists in the workout.`);
                return;
            }

            // Fetch exercise details from the database
            const exerciseRef = doc(db, 'exercises', selectedOption.value);
            const exerciseDoc = await getDoc(exerciseRef);
            
            let defaultSets = 1;
            let defaultUnits = '';
            let defaultWeight = '';
            let unitType = '';
            let videoLink = '';

            if (exerciseDoc.exists()) {
                const exerciseData = exerciseDoc.data();
                defaultSets = exerciseData.defaultSets || 1;
                defaultUnits = exerciseData.defaultUnits || '';
                defaultWeight = exerciseData.defaultWeight || '';
                unitType = exerciseData.unitType || '';
                videoLink = exerciseData.videoLink || '';
            }

            const exerciseOrderNumber = Math.max(0, ...Object.values(workout).map((exercise) => isNaN(exercise.orderNumber) ? 0 : exercise.orderNumber)) + 1;

            console.log("exerciseOrder", exerciseOrder);
            console.log("exerciseOrderNumber", exerciseOrderNumber);

            const exerciseLogData = {
                client_id: clientId,
                workout_id: workoutId,
                exercise_name: exerciseName,
                videoLink: videoLink,
                sets: {},
                created_at: serverTimestamp(),
                deleted_at: null,
                orderNumber: exerciseOrderNumber
            };

            // Create sets based on defaultSets
            for (let i = 1; i <= defaultSets; i++) {
                exerciseLogData.sets[i] = {
                    reps: defaultUnits,
                    weight: defaultWeight,
                    unitType: unitType,
                    completed: false,
                    completed_at: null
                };
            }

            await setDoc(exerciseLogRef, exerciseLogData);

            setWorkout(prevWorkout => ({
                ...prevWorkout,
                [exerciseName]: exerciseLogData
            }));
            setNewExercise(null);
            setLastSaved(new Date().toLocaleTimeString());
            setExpandedExercise(exerciseOrder.length); // Auto expand the new exercise
        } catch (error) {
            console.error('Error saving new exercise:', error);
        }
    };

    const handleNewExerciseCreate = async (inputValue) => {
        try {
            const exerciseName = inputValue;
            const exerciseRef = doc(db, 'users', coachId, 'exercises', exerciseName);

            const exerciseData = {
                name: exerciseName,
                created_at: serverTimestamp(),
            };

            await setDoc(exerciseRef, exerciseData);

            // Optionally, you can add the new exercise to the exerciseOptions state if needed
            // setExerciseOptions(prevOptions => [...prevOptions, { value: exerciseName, label: exerciseName }]);

            // Call handleNewExerciseSelect to add the exercise to the workout
            handleNewExerciseSelect({ label: exerciseName, value: exerciseName }, { action: 'create-option' });
        } catch (error) {
            console.error('Error creating new exercise:', error);
        }
    };

    const handleRepTypeChange = async (selectedOption, exerciseName) => {
        setRepType(selectedOption.value);

        // Update the rep type in Firestore for all sets
        try {
            const exerciseLogId = generateExerciseLogId(clientId, workoutId, exerciseName);
            const exerciseLogRef = doc(db, 'client_exercise_logs', exerciseLogId);

            const exercise = workout[exerciseName];
            const updatedSets = { ...exercise.sets };

            for (const setNumber in updatedSets) {
                updatedSets[setNumber].unitType = selectedOption.value;
            }

            await updateDoc(exerciseLogRef, {
                'sets': updatedSets,
            });

            setWorkout(prevWorkout => ({
                ...prevWorkout,
                [exerciseName]: {
                    ...exercise,
                    sets: updatedSets
                }
            }));

            setLastSaved(new Date().toLocaleTimeString());
        } catch (error) {
            console.error('Error updating rep type: ', error);
        }
    };

    const handleDeleteExercise = async (exerciseName) => {
        const exerciseLogId = generateExerciseLogId(clientId, workoutId, exerciseName);
        const exerciseLogRef = doc(db, 'client_exercise_logs', exerciseLogId);

        try {
            await updateDoc(exerciseLogRef, {
                deleted_at: serverTimestamp()
            });

            setWorkout(prevWorkout => {
                const updatedWorkout = { ...prevWorkout };
                delete updatedWorkout[exerciseName];
                return updatedWorkout;
            });

            setLastSaved(new Date().toLocaleTimeString());
        } catch (error) {
            console.error('Error deleting exercise: ', error);
        }
    };

    const handleAddSet = async (exerciseName) => {
        // Generate deterministic exerciseLogId
        const exerciseLogId = generateExerciseLogId(clientId, workoutId, exerciseName);
        const exerciseLogRef = doc(db, 'client_exercise_logs', exerciseLogId);

        try {
            await updateDoc(exerciseLogRef, {
                [`sets.${Object.keys(workout[exerciseName].sets).length + 1}`]: {
                    reps: '',
                    weight: '',
                    completed: false,
                    unitType: repType,
                    completed_at: null
                }
            });
            setLastSaved(new Date().toLocaleTimeString());
        } catch (error) {
            console.error('Error adding set: ', error);
        }

        setWorkout(prevWorkout => {
            const updatedWorkout = { ...prevWorkout };
            const exercise = updatedWorkout[exerciseName];
            const newSetNumber = Object.keys(exercise.sets).length + 1;
            exercise.sets[newSetNumber] = { reps: '', weight: '', completed: false, unitType: repType };

            return updatedWorkout;
        });


    };

    const handleDeleteSet = async (exerciseName, setNumber) => {
        if (!window.confirm("Are you sure you want to delete this set?")) {
            return;
        }
    
        // Generate deterministic exerciseLogId
        const exerciseLogId = generateExerciseLogId(clientId, workoutId, exerciseName);
        const exerciseLogRef = doc(db, 'client_exercise_logs', exerciseLogId);
    
        try {
            // Fetch the current sets from Firestore
            const exerciseDoc = await getDoc(exerciseLogRef);
            if (!exerciseDoc.exists()) {
                console.error('Exercise document does not exist.');
                return;
            }
    
            const exerciseData = exerciseDoc.data();
            const sets = exerciseData.sets; // Assuming 'sets' is an object
    
            // Delete the set to be removed
            delete sets[setNumber];
    
            // Reorder the sets to ensure sequential numbering
            const reorderedSets = {};
            const sortedKeys = Object.keys(sets)
                .map(Number)
                .sort((a, b) => a - b);
    
            sortedKeys.forEach((key, index) => {
                reorderedSets[index + 1] = sets[key];
            });
    
            // Update Firestore with the reordered sets
            await updateDoc(exerciseLogRef, { sets: reorderedSets });
    
            // Update local state to reflect changes
            setWorkout(prevWorkout => {
                const updatedWorkout = { ...prevWorkout };
                updatedWorkout[exerciseName].sets = reorderedSets;
                return updatedWorkout;
            });
    
            // Update the last saved timestamp
            setLastSaved(new Date().toLocaleTimeString());
        } catch (error) {
            console.error('Error deleting set: ', error);
        }
    };
    
    const repTypeOptions = [
        { value: 'Reps', label: 'Reps' },
        { value: 'Time', label: 'Time' },
        { value: 'Distance', label: 'Distance' }
    ];

    return (
        <div className="list-group list-group-flush">
            {exerciseOrder.map((exerciseName, index) => {
                const exercise = workout[exerciseName];
                return (
                    <div key={index} className="list-group-item px-0">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">{exercise.name || exerciseName}</h5>
                            {exercise.videoLink && (
                                <i
                                    className="fa-regular fa-clapperboard-play ms-2"
                                    style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={() => handleVideoLinkClick(exercise.videoLink)}
                                />
                            )}
                            <div className="ms-auto">
                                {isEditable && (
                                        <button className="btn btn-link text-danger" onClick={() => handleDeleteExercise(exerciseName)}>
                                            Delete
                                        </button>
                                )}
                                <button className="btn btn-link" onClick={() => handleExpandExercise(index)}>
                                    {expandedExercise === index ? 'Collapse' : 'Expand'}
                                </button>
                            </div>
                        </div>
                        {expandedExercise === index && (
                            <div className="table-responsive">
                                <table className="table table-striped mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>Set</th>
                                            <th scope="col" style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>{isSmallScreen ? 'Prev' : 'Previous'}</th>
                                            <th scope="col" style={{minWidth: isSmallScreen ? '80px' : '110px', padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : ''}}>
                                                <Select
                                                    value={{ value: repType, label: repType }}
                                                    options={repTypeOptions}
                                                    onChange={(selectedOption) => handleRepTypeChange(selectedOption, exerciseName)}
                                                    components={{ DropdownIndicator }}
                                                    isClearable={false}
                                                />
                                            </th>
                                            <th scope="col" style={{minWidth: isSmallScreen ? '80px' : '110px', padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : ''}}>Weight</th>
                                            <th scope="col" style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}><i className="bi bi-check-square"></i></th>
                                            {isEditable && <th scope="col" style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}><i className="bi bi-gear"></i></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(exercise.sets).map(([setNumber, set]) => (
                                            <tr key={setNumber}>
                                                <td style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>{setNumber}</td>
                                                <td style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>
                                                    <span className={isSmallScreen ? 'fs-8' : 'fs-6'}>{getPreviousSetData(exerciseName, setNumber)}</span>
                                                </td>
                                                <td style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={set.reps}
                                                        onChange={(e) => handleChange(exerciseName, setNumber, 'reps', e.target.value)}
                                                        style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? '2px' : '' }}
                                                    />
                                                </td>
                                                <td style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={set.weight}
                                                        onChange={(e) => handleChange(exerciseName, setNumber, 'weight', e.target.value)}
                                                        style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? '2px' : '' }}
                                                    />
                                                </td>
                                                <td style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={set.completed}
                                                        onChange={(e) => handleChange(exerciseName, setNumber, 'status', e.target.checked ? 'complete' : 'incomplete')}
                                                    />
                                                    {!isSmallScreen && set.completed && (
                                                        <i className="bi bi-check-circle-fill text-success ms-2"></i>
                                                    )}
                                                </td>
                                                {isEditable && (
                                                    <td style={{ padding: isSmallScreen ? smallScreenPadding : '', margin: isSmallScreen ? smallScreenMargin : '' }}>
                                                        <button className="btn btn-link text-danger" onClick={() => handleDeleteSet(exerciseName, setNumber)} style={{ padding: '0', margin: '0' }}>
                                                            x
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {isEditable && (
                                    <div className="text-center mt-2">
                                        <button className="btn btn-secondary w-100 m-0 p-0 mt-2" style={{ height: '25px' }} onClick={() => handleAddSet(exerciseName)}>+Add Set</button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
            {newExercise && (
                <div className="list-group-item px-0">
                    <CreatableSelect
                        options={exerciseOptions}
                        onChange={handleNewExerciseSelect}
                        onCreateOption={handleNewExerciseCreate}
                        placeholder="Select or create an exercise..."
                        components={{ DropdownIndicator }}
                    />
                </div>
            )}
            <div className="text-center mt-4">
                {lastSaved && (
                    <p className="text-muted fst-italic mb-2">Last autosaved at {lastSaved}</p>
                )}
                {isEditable && !newExercise && (
                    <button className="btn btn-secondary me-2" onClick={addExercise}>Add Exercise</button>
                )}
                <button className="btn btn-primary" onClick={handleComplete}>Complete Workout</button>
            </div>
        </div>
    );
};

export default ExerciseTable;
