import React from 'react';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo_dark.png';
import coachcloud_favicon_dark1 from '../assets/coachcloud_favicon_dark1.png'


const Sidebar = () => {
  const { logout } = useAuth();

  return (
    <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 py-lg-0 navbar-light bg-light border-end-lg" id="navbarVertical">
      <div className="container-fluid">
        {/* <!-- Toggler --> */}
        <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <!-- Brand --> */}
        <a className="navbar-brand py-lg-5 mb-lg-5 px-lg-6 me-0" href="/dashboard">
          <img src={logo} alt="..." className="d-none d-lg-block img-fluid"/>
        </a>
        {/* <!-- User menu (mobile) --> */}
        <div className="navbar-user d-lg-none">
          {/* <!-- Dropdown --> */}
          <div className="dropdown">
            {/* <!-- Toggle --> */}
            <a href="/dashboard" id="sidebarAvatar">
              <div className="avatar text-white">
                <img alt="..." src={coachcloud_favicon_dark1} />
              </div>
            </a>
          </div>
        </div>
        {/* <!-- Collapse --> */}
        <div className="collapse navbar-collapse" id="sidebarCollapse">
          {/* <!-- Navigation --> */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/dashboard">
                <i className="bi bi-house"></i> Dashboard
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/dashboard/clients">
                <i className="bi bi-person"></i> Clients
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/dashboard/groups">
                <i className="bi bi-people"></i> Group Programs
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/dashboard/chat">
                <i className="bi bi-chat-square-dots"></i> Chat
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="#">
                <i className="bi bi-bookmarks"></i> Collections
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                <i className="bi bi-people"></i> Users
              </a>
            </li> */}
          </ul>
          {/* <!-- Divider --> */}
          <hr className="navbar-divider my-5 opacity-20" />
          {/* <!-- Navigation --> */}
          {/* <ul className="navbar-nav mb-md-4">
            <li className="nav-item">
              <a className="nav-link" href="#">
                <i className="bi bi-gear"></i> Settings
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                <i className="bi bi-bell"></i> Notifications
                <span className="badge bg-opacity-30 bg-danger text-danger rounded-pill d-inline-flex align-items-center ms-auto">23</span>
              </a>
            </li>
          </ul> */}
          {/* <!-- Push content down --> */}
          <div className="mt-auto"></div>
          {/* <!-- User (md) --> */}
          <ul className="navbar-nav mb-5">
            <li className="nav-item">
              <a className="nav-link" href="/dashboard/account/profile">
                <i className="bi bi-person-square"></i> Account
              </a>
            </li>
            <li className="nav-item">
              <a 
                className="nav-link" 
                href="#" 
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                <i className="bi bi-box-arrow-left"></i> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};



export default Sidebar;
