import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../layouts/Coachportal_sidebar';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import Overview from '../../components/coachportal/Clients/tabs/Overview';
import Schedule from '../../components/coachportal/Clients/tabs/Schedule';
import GroupPrograms from '../../components/coachportal/Clients/tabs/GroupPrograms';
import Programs from '../../components/coachportal/Clients/tabs/Programs';
import Payments from '../../components/shared/paymentsTableUI';

import AddAppointmentModal from '../../components/coachportal/Clients/modals/AddPrivateAppointmentModal';
import AddCreditsModal from '../../components/coachportal/Clients/modals/AddCreditsModal';
import { getAuth } from 'firebase/auth';

function ClientDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [clientName, setClientName] = useState('');
  const [credits, setCredits] = useState(0);
  const [activeTab, setActiveTab] = useState('Overview');
  const [refresh, setRefresh] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [programType, setProgramType] = useState('');
  const auth = getAuth();
  const coachId = auth.currentUser.uid;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const handleAddAppointmentClick = useCallback(() => {
    setIsAppointmentModalOpen(true);
  }, []);

  const handleAddCreditsClick = () => {
      setShowCreditsModal(true);
  };

  const handleAppointmentModalClose = useCallback(() => {
    setIsAppointmentModalOpen(false);
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    const fetchClientDetails = async () => {
      const clientRef = doc(db, 'users', id);
      const clientSnapshot = await getDoc(clientRef);
      if (clientSnapshot.exists()) {
        const clientData = clientSnapshot.data();
        const fullName = `${clientData.firstName} ${clientData.lastName}`;
        setClientName(fullName);
        setCredits(clientData.credits);
        setProgramType(clientData.programType || '');
      }
    };

    fetchClientDetails();
  }, [id, refresh]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <Overview />;
      case 'Schedule':
        return (<Schedule refreshKey={refreshKey} setRefreshKey={setRefreshKey} />)
      case 'Programs':
        return programType === 'individual' ? <Programs clientId={id} /> : <GroupPrograms clientId={id} />;
      case 'Transactions':
        return (<Payments userId={id} refresh={refresh} setRefresh={setRefresh} />)
      default:
        return <Overview />;
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/dashboard/clients/${id}?tab=${tab}`);
  };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />
      <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-lg-auto">
        <div className="container-fluid">
          <a href="/dashboard/clients" className="pt-5 mb-5 btn btn-light btn-sm">&larr; Back</a>
          <div className="card pt-3 m-4 rounded-0">
            <div className="card-body py-5 row">
              <div className="col-auto position-relative d-inline-block text-white"> 
                <div className="avatar rounded-circle">
                  <img
                    alt="..."
                    src={`https://ui-avatars.com/api/?name=${clientName}&background=random&rounded=true`}
                  />
                </div>
              </div>
              <div className="col">
                <span className="d-block h5 mb-1">{clientName}</span>
                <span className="d-block text-sm">Last Appointment: </span>
                <span className="d-block text-sm mt-2 font-italic">Credits: {credits}</span>
                <div className="d-flex align-items-center mt-2">
                  <button onClick={handleAddCreditsClick} className="btn btn-soft-primary btn-sm p-1"> + Add Credits</button>
                  <button onClick={handleAddAppointmentClick} className="btn btn-soft-primary btn-sm ms-3 p-1"> + Add Appointment </button>
                </div>
              </div>
            </div>
          </div>
          <ul className="nav nav-pills ms-4 pt-5">
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'Overview' ? 'active' : ''}`} onClick={() => handleTabChange('Overview')}>Overview</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'Schedule' ? 'active' : ''}`} onClick={() => handleTabChange('Schedule')}>Sessions</button>
            </li>
            <li className="nav-item">
                <button className={`nav-link ${activeTab === 'Programs' ? 'active' : ''}`} onClick={() => handleTabChange('Programs')}>Programs</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'Transactions' ? 'active' : ''}`} onClick={() => handleTabChange('Transactions')}>Transactions</button>
            </li>
          </ul>
          <div className="py-6 bg-surface-primary">
            <div className="card pt-3 m-4 rounded-0">
              {renderTabContent()} 
            </div>
          </div>
        </div>
      </div>

      {isAppointmentModalOpen && (
        <AddAppointmentModal
          clientUserId={id}
          coachId={coachId}
          show={isAppointmentModalOpen}
          onClose={handleAppointmentModalClose}
          onAppointmentAdded={() => setRefreshKey(prevKey => prevKey + 1)}
        />
      )}

      {showCreditsModal && (
        <AddCreditsModal
          onClose={() => {
            setShowCreditsModal(false);
            setRefresh(!refresh);
          }}
        />
      )}
    </div>
  );
}

export default ClientDetails;
