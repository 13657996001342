// EditWorkout.js
import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { db } from '../../firebase/firebase';
import { doc, getDoc, updateDoc, collection, addDoc, getDocs } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../layouts/Coachportal_sidebar';
import Header from '../../layouts/Header';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getAuth } from 'firebase/auth';

const EditWorkout = () => {
    const [workoutName, setWorkoutName] = useState('');
    const [duration, setDuration] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [dateAssigned, setDateAssigned] = useState('');
    const [exercises, setExercises] = useState([]);
    const [exerciseOptions, setExerciseOptions] = useState([]);

    const [videoLink, setVideoLink] = useState(''); // Video link state
    const [videoModal, setVideoModal] = useState(false); // Video link state
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(null);

    const navigate = useNavigate();
    const { workoutId } = useParams();  // Assuming the route is /edit-workout/:workoutId
    const auth = getAuth();
    const coachId = auth.currentUser.uid;

    useEffect(() => {
        const fetchWorkoutData = async () => {
            const workoutRef = doc(db, 'assigned_workouts', workoutId);
            const workoutSnap = await getDoc(workoutRef);

            if (workoutSnap.exists()) {
                const workoutData = workoutSnap.data();
                setWorkoutName(workoutData.name ?? '');
                setDuration(workoutData.duration ?? '');
                setDifficulty(workoutData.difficulty ?? '');
                setDateAssigned(workoutData.dateAssigned ?? '');
                setExercises(workoutData.exercises || []);
            }
        };

        const fetchExercises = async () => {
            // Fetch coach-specific exercises
                const coachExercisesSnapshot = await getDocs(collection(db, "users", coachId, "exercises"));
                const coachExercises = coachExercisesSnapshot.docs.map(doc => ({
                    label: doc.data().name,
                    value: doc.id,
                    defaultSets: doc.data().defaultSets || '',
                    defaultUnits: doc.data().defaultUnits || '',
                    unitType: doc.data().unitType || 'reps',
                    defaultWeight: doc.data().defaultWeight || ''
                }));
    
                // Fetch general exercises
                const generalExercisesSnapshot = await getDocs(collection(db, "exercises"));
                const generalExercises = generalExercisesSnapshot.docs.map(doc => ({
                    label: doc.data().name,
                    value: doc.id,
                    defaultSets: doc.data().defaultSets || '',
                    defaultUnits: doc.data().defaultUnits || '',
                    unitType: doc.data().unitType || 'reps',
                    defaultWeight: doc.data().defaultWeight || ''
                }));
    
                const combinedExercises = [...coachExercises, ...generalExercises];
                const uniqueExercises = Array.from(new Map(combinedExercises.map(ex => [ex.label, ex])).values());
                
                setExerciseOptions(uniqueExercises);
            };
    
        fetchExercises();
        fetchWorkoutData();
    }, [workoutId, coachId]);

    // Video modal
    const handleVideoIconClick = (index) => {
        setCurrentExerciseIndex(index);
        setVideoLink(exercises[index].videoLink || '');
        setVideoModal(true);
    };

    const handleSaveVideoLink = () => {
        const newExercises = exercises.map((exercise, i) => {
            if (i === currentExerciseIndex) {
                return { ...exercise, videoLink };
            }
            return exercise;
        });
        setExercises(newExercises);
        setVideoModal(false);
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        const workoutRef = doc(db, 'assigned_workouts', workoutId);
        const updatedData = {
            name: workoutName,
            duration,
            difficulty,
            dateAssigned: dateAssigned,
            exercises
        };

        try {
            await updateDoc(workoutRef, updatedData);
            navigate(-1); // Navigate back after update
        } catch (error) {
            console.error('Error updating workout: ', error);
        }
    };

    const handleDeleteExercise = index => {
        // Remove the exercise at the given index
        setExercises(currentExercises => currentExercises.filter((_, i) => i !== index));
    };

    const handleAddExercise = () => {
        setExercises([...exercises, { name: '', sets: '', reps: '', weight: '', unitType: 'reps' }]);
    };

    const handleCreateExercise = async (inputValue, index) => {
        try {
            const newExerciseRef = await addDoc(collection(db, "users", coachId, "exercises"), { name: inputValue });
            const newOption = { label: inputValue, value: newExerciseRef.id };
            setExerciseOptions(prevOptions => [...prevOptions, newOption]);
            handleExerciseChange(index, 'name', newOption.label);
        } catch (error) {
            console.error("Error creating new exercise: ", error);
        }
    };

    const DropdownIndicator = props => {
        return null;
    };

    const handleExerciseChange = (index, field, value, selectedOption) => {
        const newExercises = exercises.map((exercise, i) => {
            if (i === index) {
                if (field === 'name' && selectedOption) {
                    return {
                        ...exercise,
                        name: value,
                        sets: selectedOption.defaultSets,
                        reps: selectedOption.defaultUnits,
                        unitType: selectedOption.unitType,
                        weight: selectedOption.defaultWeight,
                    };
                }
                return { ...exercise, [field]: value };
            }
            return exercise;
        });
        setExercises(newExercises);
    };

    return (
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
            <Sidebar />
            <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-lg-auto">
                <Header title="Edit Workout" />
                <div className="container-fluid">
                    <form onSubmit={handleSaveChanges}>
                        <div className="card m-4 rounded-0">
                            <div className="card-body">
                                <div className="mb-4">
                                    <input
                                        type="text"
                                        className="form-control-lg"
                                        style={{ fontSize: "1.5rem", height: "auto", padding: "0px 10px" }}
                                        placeholder="Workout Name"
                                        value={workoutName || ''}
                                        onChange={(e) => setWorkoutName(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-floating">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Estimated Length (minutes)"
                                                value={duration || ''}
                                                onChange={(e) => setDuration(e.target.value)}
                                            />
                                            <label htmlFor="floatingInput">
                                                <i className="bi bi-clock-history me-2">  Minutes</i>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-floating">
                                            <select
                                                className="form-select"
                                                value={difficulty || ''}
                                                onChange={(e) => setDifficulty(e.target.value)}
                                            >
                                                <option value="">Select difficulty</option>
                                                <option value="easy">Easy</option>
                                                <option value="medium">Medium</option>
                                                <option value="hard">Hard</option>
                                                <option value="insanity">Insanity</option>
                                            </select>
                                            <label htmlFor="floatingSelect">
                                                <i className="bi bi-bar-chart-steps me-2"></i>Difficulty
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-floating">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={dateAssigned || ''}
                                                onChange={(e) => setDateAssigned(e.target.value)}
                                                required
                                            />
                                            <label htmlFor="floatingInputDate">
                                                <i className="bi bi-calendar3 me-2"></i>Date Assigned
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card rounded-0 m-4">
                            <div className="card-header border-bottom d-flex align-items-center">
                                <h5 className="me-auto h3">Exercises</h5>
                                <button type="button" onClick={handleAddExercise}>+ Add Row</button>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" className="d-none d-md-table-cell"></th>
                                            <th scope="col">Exercise Name</th>
                                            <th scope="col" style={{ minWidth: "105px" }}>Sets</th>
                                            <th scope="col">Units</th>
                                            <th scope="col" style={{ minWidth: "105px" }}>Unit Type</th>
                                            <th scope="col">Weight (lbs)</th>
                                            <th scope="col"><i className="fa-sharp fa-solid fa-video"></i></th>
                                            <th scope="col" className="d-none d-md-table-cell">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {exercises.map((exercise, index) => (
                                            <tr key={index}>
                                                <td className="d-none d-md-table-cell">{index + 1}</td>
                                                <td>
                                                    <CreatableSelect
                                                        isClearable
                                                        isSearchable
                                                        value={exercise.name ? { label: exercise.name, value: exercise.name } : ''}
                                                        onChange={(selectedOption) => handleExerciseChange(index, 'name', selectedOption ? selectedOption.label : '', selectedOption)}
                                                        options={exerciseOptions}
                                                        onCreateOption={(inputValue) => handleCreateExercise(inputValue, index)}
                                                        placeholder="Add an exercise"
                                                        components={{ DropdownIndicator }}
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={exercise.sets || ''}
                                                        onChange={(e) => handleExerciseChange(index, 'sets', e.target.value)}
                                                    />
                                                </td>

                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={exercise.reps || ''}
                                                        onChange={(e) => handleExerciseChange(index, 'reps', e.target.value)}
                                                        placeholder={exercise.unitType === 'time' ? 'Minutes' : exercise.unitType === 'distance' ? 'Meters' : 'Reps'}
                                                    />
                                                </td>

                                                <td>
                                                    <select
                                                        className="form-control"
                                                        value={exercise.unitType || ''}
                                                        onChange={(e) => handleExerciseChange(index, 'unitType', e.target.value)}
                                                    >
                                                        <option value="reps">Reps</option>
                                                        <option value="time">Time</option>
                                                        <option value="distance">Distance</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={exercise.weight || ''}
                                                        onChange={(e) => handleExerciseChange(index, 'weight', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <i
                                                        className="fa-regular fa-clapperboard-play"
                                                        style={{ color: exercise.videoLink ? 'blue' : 'gray', cursor: 'pointer' }}
                                                        onClick={() => handleVideoIconClick(index)}
                                                    />
                                                </td>
                                                <td className="d-none d-md-table-cell" >
                                                    <button className="btn-sm" onClick={() => handleDeleteExercise(index)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success w-100">Save Changes</button>
                    </form>
                </div>
                {/* Modal for Editing Video link */}
                <Modal show={videoModal} onHide={() => setVideoModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Video Link</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Enter video URL"
                        value={videoLink}
                        onChange={(e) => setVideoLink(e.target.value)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setVideoModal(false)}>
                        Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSaveVideoLink}>
                        Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default EditWorkout;
