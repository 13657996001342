import React, { useState, useEffect } from 'react';
import logo_dark from '../../assets/logo_dark.png';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function MobileHome() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
          // Only use the Keyboard plugin in native mobile apps
          Keyboard.setResizeMode({ mode: 'body' });
    
          const showListener = Keyboard.addListener('keyboardWillShow', (info) => {
            console.log('Keyboard will show, height:', info.keyboardHeight);
          });
    
          const hideListener = Keyboard.addListener('keyboardWillHide', () => {
            console.log('Keyboard will hide');
          });
    
          // Clean up event listeners when the component is unmounted
          return () => {
            showListener.remove();
            hideListener.remove();
          };
        } else {
          console.log("Running in web environment, skipping Keyboard plugin.");
        }
      }, []);

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            const auth = getAuth();
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log("Logged in user:", user);

            const db = getFirestore();
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                
                if (userData.role === 'client' && userData.coachUid) {
                    const coachDoc = await getDoc(doc(db, "users", userData.coachUid));
                    if (coachDoc.exists()) {
                        const coachData = coachDoc.data();
                        navigate(`/website/${coachData.username}/dashboard`);
                    } else {
                        console.error("Coach document doesn't exist");
                        alert("Error: Coach not found");
                    }
                } else if (userData.role === 'coach') {
                    navigate('/dashboard');
                } else {
                    console.error("User is not a client or doesn't have a coach assigned");
                    alert("Error: Invalid user role or missing coach assignment");
                }
            } else {
                console.error("User document doesn't exist");
                alert("Error: User data not found");
            }
        } catch (error) {
            console.error("Login error:", error);
            alert(error.message);
        }
    };



    return (
        <div className="d-flex align-items-center min-vh-100 px-5 py-5 p-lg-0">
            <div className="w-100">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-9 col-lg-7">
                        <div className="row">
                            <div className="col-lg-10 col-md-9 col-xl-7 mx-auto">
                                <div className="text-center mb-12">
                                    <span className="d-inline-block d-lg-block h1 mb-lg-6 me-3">
                                        <img
                                            src={logo_dark}
                                            className="h-14"
                                            alt="Logo"
                                        />
                                    </span>
                                    {/* <a className="d-inline-block" href="#">
                                        <img src={logo_dark} className="h-12" alt="Logo" />
                                    </a> */}

                                </div>
                                <form className="pt-5" onSubmit={loginUser}>
                                    <div className="mb-5">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Your email address"
                                            onChange={(e) => setEmail(e.target.value)}
                                            onFocus={(e) => e.target.focus()}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="Password"
                                            autoComplete="current-password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            onFocus={(e) => e.target.focus()}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="check_example"
                                                id="check_example"
                                            />
                                            <label className="form-check-label" htmlFor="check_example">
                                                Keep me logged in
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary w-full">
                                        Sign In
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileHome;