import React, { useState, useEffect, useCallback } from "react";
import { doc, getDoc, query, collection, where, getDocs, orderBy, limit } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase/firebase';
import ClientNavbar from '../../layouts/ClientNavbar';
import { useParams } from 'react-router-dom';
import backgroundImage from '../../assets/fitness_photos/anastase-maragos-fG0p4Qh_aWI-unsplash.jpg';
import { useNavigate } from 'react-router-dom';



function Home() {
    
    const { username } = useParams()
    const [credits, setCredits] = useState(null);
    const [firstName, setfirstName] = useState(null);
    const [heading1, setHeading1] = useState(null);
    const [heading2, setHeading2] = useState("Loading");
    const [heading3, setHeading3] = useState("Loading");
    const [userDoc, setUserDoc] = useState("Loading");
    const [detailsLink, setDetailsLink] = useState(null);

    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();


    const fetchCredits = useCallback(() => {
        setCredits(userDoc.credits);
        setfirstName(userDoc.firstName);
    }, [userDoc]);

    useEffect(() => {
        // Empty useEffect
    
        const fetchAppointmentData = async () => {
            const appointmentsRef = collection(db, "appointments");
            const q = query(
                appointmentsRef,
                where("clientId", "==", user.uid),
                where("isDeleted", "==", false),
                where("isCancelled", "==", false),
                where("appointmentDateTime", ">=", new Date()),
                orderBy("appointmentDateTime", "asc"),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            console.log(querySnapshot)
            const nextAppt = querySnapshot.docs.length > 0 ? { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() } : "noUpcomingSessions";

            // Initialize a flag to track if the appointment is today
            let nextApptToday = false;

            // Check if the next appointment is today
            if (nextAppt !== "noUpcomingSessions") {
                const appointmentDateTime = new Date(nextAppt.appointmentDateTime.toDate());
                const todayReset = new Date();
                todayReset.setHours(0, 0, 0, 0);

                const appointmentDateReset = new Date(appointmentDateTime);
                appointmentDateReset.setHours(0, 0, 0, 0);

                // Check if the appointment is today
                nextApptToday = appointmentDateReset.getTime() === todayReset.getTime();
            }

            return { nextAppt, nextApptToday };
        }

        const formatAppointmentDate = (enteredDate) => {
            // Create a new Date object for the appointment date and time
            const appointmentDateTime = new Date(enteredDate.toDate());
            
            // Format the hour and minute using toLocaleString
            const hour = appointmentDateTime.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
            
            // Comparing dates by setting time to start of the day
            const todayReset = new Date();
            todayReset.setHours(0, 0, 0, 0);
            
            const tomorrowReset = new Date(todayReset);
            tomorrowReset.setDate(tomorrowReset.getDate() + 1);
            
            const appointmentDateReset = new Date(appointmentDateTime);
            appointmentDateReset.setHours(0, 0, 0, 0);

            // Compare dates and set the heading appropriately
            if (appointmentDateReset.getTime() === todayReset.getTime()) {
                return `Today @ ${hour}`;
            } else if (appointmentDateReset.getTime() === tomorrowReset.getTime()) {
                return `Tomorrow @ ${hour}`;
            } else {
                // Extract and format the month, day, and year for other dates
                const month = `0${appointmentDateReset.getMonth() + 1}`.slice(-2);
                const day = `0${appointmentDateReset.getDate()}`.slice(-2);
                const year = appointmentDateReset.getFullYear();
                return `${month}/${day}/${year} @ ${hour}`;
            }
        }

        const fetchWorkoutData = async (id, type) => {
            let todayWorkout = null;

            if (id) {
                const today = new Date();
                const todayString = today.toISOString().split('T')[0]; // Format: "YYYY-MM-DD"

                // Query assigned workouts where fitness_group matches and dateAssigned is today
                const q = query(
                    collection(db, "assigned_workouts"),
                    where(type === "group" ? "groupId" : "clientId", "==", id),
                    where("dateAssigned", "==", todayString)
                );

                const querySnapshot = await getDocs(q);


                if (!querySnapshot.empty) {
                    // If there is a workout assigned for today, retrieve and display it
                    todayWorkout = querySnapshot.docs[0].data(); // Assume there's only one workout per day
                    todayWorkout.id =  querySnapshot.docs[0].id;
                    console.log(todayWorkout); 
                } else {
                    todayWorkout=null
                }
            }

            return todayWorkout;
        }
        
        const fetchData = async () => {
            const { nextAppt, nextApptToday } = await fetchAppointmentData();
            let todayWorkout = null;

            if (nextApptToday === false) {
                console.log("userDoc", userDoc)
                if (userDoc.programType === "group") {
                    todayWorkout = await fetchWorkoutData(userDoc.fitness_group, "group");
                } else if (userDoc.programType === "individual") {
                    todayWorkout = await fetchWorkoutData(user.uid, "personalized");
                } else {
                    todayWorkout = null;
                }
            }

            console.log(nextApptToday);
            console.log(nextAppt);
            console.log(todayWorkout)

            if (nextApptToday === true) {
                console.log("scenario 0");
                setDetailsLink(`/website/${username}/scheduling`);
                setHeading1(formatAppointmentDate(nextAppt.appointmentDateTime));
                if (nextAppt.portal_notes !== "") {
                    setHeading2(nextAppt.portal_notes);
                    setHeading3(`${nextAppt.type} with ${username} - ${nextAppt.location_type}`);
                } else {
                    setHeading2(`${nextAppt.type} with ${username}`);
                    setHeading3(nextAppt.location_type);
                }
            } else if (nextApptToday === false && todayWorkout !== null) {
                console.log("scenario 1")
                setDetailsLink(`/website/${username}/workout-preview/${todayWorkout.id}`);
                setHeading2(`${todayWorkout.name}`);
                setHeading1(todayWorkout.description);
                setHeading3('Ready to get started?');
            } else if (nextAppt === "noUpcomingSessions") {
                console.log("scenario 2")
                setHeading2('No workout assigned for today.');
                setHeading3('Perhaps you should book an appointment?');
                setDetailsLink(`/website/${username}/scheduling`);
            } else {
                console.log("scenario 3")
                setDetailsLink(`/website/${username}/scheduling`);
                // set h2 and h3 based on portal notes
                if (nextAppt.portal_notes !== "") {
                    setHeading2(nextAppt.portal_notes);
                    setHeading3(`${nextAppt.type} with ${username} - ${nextAppt.location_type}`);
                } else {
                    setHeading2(`${nextAppt.type} with ${username}`);
                    setHeading3(nextAppt.location_type);
                }

                //set h1 based on date
                setHeading1(formatAppointmentDate(nextAppt.appointmentDateTime));
            }
        }

        if (userDoc !== "Loading") {
            fetchData();
        }
    }, [userDoc, user.uid, username]);

    useEffect(() => {

        const fetchUserInformation = async() => {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            const userDoci = docSnap.data();

            if (docSnap.exists()) {
                setUserDoc(userDoci);
                

            } else {
                console.log("No such document!");
            }
        }

       fetchUserInformation();
    }, [user.uid]);
    
    // Second useEffect to fetch credits after userDoc is updated
    useEffect(() => {
        if (userDoc !== "Loading") {
            fetchCredits();
        }
    }, [userDoc, fetchCredits]);  // Runs only when userDoc changes
    

    return (
        <div className="d-flex flex-column flex-md-row h-lg-full relative">
            <ClientNavbar username={username} />

            <div className="pseudo-bg h-screen flex-grow-1 overflow-y-lg-auto px-6 py-4 d-flex flex-column justify-content-between" style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100vh',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'contrast(50%) brightness(60%)', // Adjust both contrast and brightness values
                    zIndex: -1 // Ensures the background is behind other content
                }}>
            </div>
            

            <div className="h-screen flex-grow-1 overflow-y-lg-auto px-6 d-flex flex-column justify-content-between">
                <div className="d-flex align-items-center text-white py-4 pb-0 w-100">
                    <p className="text-white" style={{fontSize: "1.3rem", fontWeight: "light"}}><i>Hi, {firstName !== null ? firstName : 'Ldng...'}</i></p>
                    <div className="ms-auto d-flex align-items-center">

                        {/* <button type="button" className="btn btn-sm btn-black text-white d-flex align-items-center justify-content-center me-1" style={{fontSize: "3.5rem"}}>
                            <i className="bi bi-plus"></i>
                        </button> */}

                        <div className="icon icon-shape bg-yellow-600 rounded-circle ms-1 text-white h2 mb-0">{credits !== null ? credits : 'Ldng...'}</div>
                    </div>
                </div>

                <div className = "pb-10">
                    <div className="d-flex flex-column pb-20 justify-content-end" style={{paddingTop: '-10rem'}}>
                        <p className="text-white mb-2" style={{fontSize: "1.2rem"}}>{heading1 ? heading1 : ''}</p>
                        <h1 className="h1 mb-0 ls-tight text-white py-4" style={{fontSize: "2.5rem"}}>{heading2.charAt(0).toUpperCase() + heading2.slice(1)}</h1>
                        <p className="text-white" style={{fontSize: "1.2rem"}}>{heading3.charAt(0).toUpperCase() + heading3.slice(1)}</p>
                        <div className="d-flex justify-content-end px-6 py-10 pb-20">

                        <button 
                            type="button" 
                            className="btn py-2 bg-transparent border border-white rounded-pill text-white"
                            onClick={() => {
                            if (detailsLink) {
                                navigate(detailsLink);
                            }
                            }}
                        >        
                            See details &nbsp; &nbsp; &nbsp; →
                        </button>


                        </div>
                    </div>
                </div>

            </div>

        </div>

    );
}

export default Home;
