import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebase'; 
import logo from '../../assets/logo.png';
import { createUserDocument, checkUsernameAvailability} from '../../utils/user'; // import createUserDocument
import { formatPhoneNumber } from '../../utils/formValidation'

import { createUserWithEmailAndPassword } from "firebase/auth";

function SignUpPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('Fitness Coach');
  const [username, setUsername] = useState('');
  const [usernameAvailability, setUsernameAvailability] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  const checkAvailability = async (username) => {
    if (username.length < 6) {
      setUsernameAvailability('Username must be at least 6 characters long.');
      return;
    }
    const availability = await checkUsernameAvailability(username);
    setUsernameAvailability(availability);
  };
  
  const register = async (e) => {
    e.preventDefault();
  
    if (!email || !password || !username || !firstName) {
      alert("Please fill in all required fields (Email, Password, Handle, and First Name)");
      return;
    }

    if (username.length < 6) {
      alert("Username must be at least 6 characters long.");
      return;
    }

    try {
      const response = await fetch('/api/create-client', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, firstName, lastName }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create user');
      }

      const { uid } = await response.json();
      const userCredential = { user: { uid, email } };
      await createUserDocument(userCredential.user, 'coach', '', firstName, lastName, businessName, businessType, username);

      navigate('/dashboard');
  
    } catch (error) {
      alert(error.message);
      // Handle any errors that occurred in the process
    }
  };

  return (
    <div className="px-5 py-5 p-lg-0 bg-surface-secondary">
      <div className="d-flex justify-content-center">
        <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column">
          {/* Logo */}
          <a className="d-block" href="#">
            <img
                src={logo}
                className="h-10"
                alt="..."
            />
          </a>
          {/* Title */}
          <div className="mt-32 mb-20">
            <h1 className="ls-tight font-bolder display-6 text-white mb-5">
              Hello :)
            </h1>
            <p className="text-white text-opacity-75">
              Get ready to launch your very own customized group fitness app
            </p>
          </div>
          {/* Circle */}
          <div className="w-56 h-56 bg-orange-500 rounded-circle position-absolute bottom-0 end-20 transform translate-y-1/3" />
        </div>
        <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
          <div className="row">
            <div className="col-lg-10 col-md-9 col-xl-9 mx-auto ms-xl-0">
              <div className="mt-10 mt-lg-5 mb-6 d-flex align-items-center d-lg-block">
                <h1 className="ls-tight font-bolder h2">Let's get started...</h1>
              </div>

              <form className="mb-6" onSubmit={register}>

                {/* Email & password */}
                <div className="row g-5 mb-5">
                  <div className="col-12">
                    <div className="">
                      <label className="form-label" htmlFor="email">
                        Email*
                      </label>
                      <input type="email" className="form-control" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} id="email" required />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="">
                      <label className="form-label" htmlFor="address">
                        Password*
                      </label>
                      <input type="password" className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} id="address" required />
                    </div>
                  </div>
                </div>

                {/* Phone Number Input */}
                <div className="row mb-5">
                  <div className="col-12">
                    <div className="">
                      <label className="form-label" htmlFor="phone_number">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
                        id="phone_number"
                      />
                    </div>
                  </div>
                </div>
                {/* Username */}
                <div className="col-12 mb-5">
                  <div className="">
                      <label className="form-label" htmlFor="username">Handle*</label>
                      <div className="input-group position-relative">
                          <span className="input-group-text" id="">coachily.com/website/</span>
                          <input
                              type="text"
                              className="form-control"
                              id="username"
                              value={username}
                              onChange={(e) => {
                                setUsername(e.target.value);
                                checkAvailability(e.target.value);
                              }}
                              required
                              minLength="6"
                          />
                      </div>
                      {usernameAvailability === 'Username already taken, please choose another one.' || usernameAvailability === 'Username must be at least 6 characters long.' ? (
                          <div className="mt-2 invalid-feedback d-block">{usernameAvailability}</div>
                      ) : (
                          <div className="mt-2 valid-feedback d-block">{usernameAvailability}</div>
                      )}
                  </div>
                </div>
                {/* First Name & Last Name */}
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label" htmlFor="first_name">
                        First name*
                      </label>
                      <input type="text" className="form-control" placeholder="First Name" id="first_name"  value={firstName} onChange={e => setFirstName(e.target.value)} required />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label" htmlFor="last_name">
                        Last name
                      </label>
                      <input type="text" className="form-control" placeholder="Last Name" id="last_name" value={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                  </div>

                </div>

                <div className="row mb-8">
                  {/* Business Name */}
                  <div className="col-md-6">
                      <div className="">
                          <label className="form-label" htmlFor="formInputExample">
                          Business Name
                          </label>
                          <input 
                            type="text" 
                            className="form-control" 
                            id="formInputExample"
                            placeholder="Business Name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                          />
                      </div>
                  </div>

                  {/* Business Type */}
                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label" htmlFor="businessType">
                      Business Type
                      </label>
                      <select 
                        className="form-select" 
                        id="businessType"
                        value={businessType}
                        onChange={e => setBusinessType(e.target.value)}
                      >
                          <option value="" disabled>Select your option</option>
                          <option value="Chess Coach">Chess Coach</option>
                          <option value="Fitness Coach">Fitness Coach</option>
                      </select>
                    </div>
                  </div>

                </div>

              <div>
                <button className="btn btn-primary w-full" type="submit">
                  Save and Continue
                </button>
              </div>          
              </form>

              <div className="my-6">
                <small>Already have an account? <Link to="/login" className="text-warning text-sm font-semibold">Login</Link> </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
