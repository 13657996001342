// Payments.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { doc, getDoc, collection } from 'firebase/firestore';

const Payments = ({ userId }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      try {
        const userDocRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userDocRef);

        if (!userSnapshot.exists()) {
          console.error("User not found!");
          return;
        }

        const userTransactions = userSnapshot.data().transactions || [];
        const transactionsCollection = collection(db, "transactions");
        const transactionsData = [];

        for (const transactionId of userTransactions) {
          const transactionDocRef = doc(transactionsCollection, transactionId);
          const transactionSnap = await getDoc(transactionDocRef);
          if (transactionSnap.exists()) {
            transactionsData.push({ id: transactionSnap.id, ...transactionSnap.data() });
          }
        }

        setTransactions(transactionsData);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [userId]);

  return (
    <div>
      <div className="mb-5 mx-5 mt-2">
        <h5 className="mb-0">Payments</h5>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-nowrap">
          <thead className="table-light">
            <tr>
              <th scope="col">Date</th>
              <th scope="col" className="d-none d-sm-table-cell">Product Name</th>
              <th scope="col">Charge</th>
              <th scope="col">Credits Added</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="text-center">Loading...</td>
              </tr>
            ) : transactions.length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center">No transactions created</td>
              </tr>
            ) : (
              transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{transaction.date ? transaction.date.toDate().toLocaleDateString() : 'No date'}</td>
                  <td className="d-none d-sm-table-cell">{transaction.product_name}</td>
                  <td>${(transaction.cost / 100).toFixed(2)}</td>
                  <td>{transaction.credits_change}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payments;