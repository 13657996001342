// WorkoutDetailsForm.js
import React, { useState, useEffect } from 'react';
import { query, collection, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useIsSmallScreen } from '../../hooks/useWindowWidth';

const WorkoutDetailsForm = ({
 completedWorkoutDetails,
 setCompletedWorkoutDetails,
 setExercises,
 coachId,
 type,
 groupId,
 workoutId,
 workoutCollection,
 clientId, // Renamed from id to clientId
 defaultExercises
}) => {
 const [isGenerating, setIsGenerating] = useState(false);
 const [customPrompt, setCustomPrompt] = useState('');
 const [modalPrompt, setModalPrompt] = useState(''); // State for editing the prompt
 const [showModal, setShowModal] = useState(false); // State for modal visibility
 const [open, setOpen] = useState(type === 'coach_live_session' ? false : true); // State for collapsible section
 const [typingTimeout, setTypingTimeout] = useState(null); // State for typing timeout

 const isSmallScreen = useIsSmallScreen();
 const inputStyle = isSmallScreen ? { padding: '2px', margin: '1px' } : {};

 // Function to fetch other workouts in the same group or for the same client
 const fetchOtherWorkouts = async () => {
   let q;
   if (type === 'group') {
     q = query(collection(db, 'assigned_workouts'), where('group', '==', groupId));
   } else if (type === 'personalized') {
     q = query(collection(db, 'assigned_workouts'), where('clientId', '==', clientId)); // Updated to use clientId
   }
   const otherWorkoutsSnapshot = await getDocs(q);
   return otherWorkoutsSnapshot.docs.map((doc) => doc.data());
 };

 // Function to update Firestore document
 const updateFirestore = async (field, value) => {
   try {
     const workoutDocRef = doc(db, workoutCollection, workoutId);
     await updateDoc(workoutDocRef, {
       [`${field}`]: value,
     });
     console.log(`Successfully updated ${field} to ${value}`);
   } catch (error) {
     console.error('Error updating document:', error);
   }
 };

 // Function to handle input change with debounce
 const handleInputChange = (field, value) => {
   setCompletedWorkoutDetails((prevDetails) => ({ ...prevDetails, [field]: value }));

   if (typingTimeout) {
     clearTimeout(typingTimeout);
   }

   setTypingTimeout(setTimeout(() => {
     updateFirestore(field, value);
   }, 1000)); // Update Firestore after 1 second of inactivity
 };

 // Function to generate workout inside the component itself
 const handleGenerateWorkout = async () => {
   setIsGenerating(true);
   try {
     const otherWorkouts = await fetchOtherWorkouts();

     const prompt = customPrompt || `
       Generate a workout plan based on the following details:
       Workout Name: ${completedWorkoutDetails.workoutName || 'Unnamed'}
       Duration: ${completedWorkoutDetails.duration} minutes
       Difficulty: ${completedWorkoutDetails.difficulty}
       Date Assigned: ${completedWorkoutDetails.dateAssigned}
       Other Workouts in the Program: ${JSON.stringify(otherWorkouts)}
     `;

     console.log(prompt);

     const response = await fetch('/api/generate-workouts', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({
         workoutDetails: {
           type: 'Full Body',
           difficulty: completedWorkoutDetails.difficulty,
           duration: completedWorkoutDetails.duration,
         },
         default_exercises: defaultExercises,
         prompt: prompt,
       }),
     });

     const data = await response.json();
     console.log('Generated workout:', data);

     let rawResponse = data;

     // Clean the response: Remove markdown-like syntax like backticks and "```json"
     rawResponse = rawResponse.replace(/```json|```/g, '');

     let workoutPlan;
     try {
       workoutPlan = JSON.parse(rawResponse); // Parse the workout string as JSON
     } catch (error) {
       console.error('Error parsing workout plan JSON:', error);
       alert('Failed to parse the workout plan');
       setIsGenerating(false);
       return;
     }

     if (workoutPlan && Array.isArray(workoutPlan)) {
       const generatedExercises = workoutPlan.map((ex) => ({
         name: ex.name,
         sets: ex.sets,
         reps: ex.unit,
         weight: ex.weight,
         unitType: ex.unitType,
       }));
       setExercises(generatedExercises);
     } else {
       alert('Failed to generate workout. No exercises found.');
     }
   } catch (error) {
     console.error('Error generating workout:', error);
     alert('An error occurred while generating the workout.');
   }
   setIsGenerating(false);
 };

 // Function to open modal
 const handleOpenModal = async () => {
   // Set the default prompt if customPrompt is empty
   if (!customPrompt) {
     const otherWorkouts = await fetchOtherWorkouts(); // Fetch other workouts
     const defaultPrompt = `
       Generate a workout plan based on the following details, with the most important being duration:
       Workout Name: ${completedWorkoutDetails.workoutName || 'Unnamed'}
       Duration (length of workout): Approximately ${completedWorkoutDetails.duration} minutes, including rest time, reps, and sets. Assume a 90 second rest between sets. Less exercises are okay for shorter sets, and more exercises are okay for longer sets.
       Difficulty: ${completedWorkoutDetails.difficulty}
       Date Assigned: ${completedWorkoutDetails.dateAssigned}
       Other Workouts in the Program: ${JSON.stringify(otherWorkouts)}
     `;
     setModalPrompt(defaultPrompt);
   }
   setShowModal(true);
 };

 const handleCloseModal = () => {
   setShowModal(false);
 };

 const handleSavePrompt = () => {
   setCustomPrompt(modalPrompt);
   setShowModal(false);
 };

 return (
   <div className={`${isSmallScreen ? 'mt-4' : 'card m-4'} rounded-0`}>
     <div className="card-body">
       <div className="mb-4 d-flex align-items-center">
         <input
           type="text"
           className="form-control-lg"
           style={{ fontSize: "1.5rem", height: "auto", padding: "0px 10px" }}  // Custom style for even larger appearance
           placeholder="Workout Name"
           value={completedWorkoutDetails.workoutName}
           onChange={(e) => handleInputChange('workoutName', e.target.value)}
           required
         />
         <button
           className="btn btn-link ms-auto"
           onClick={() => setOpen(!open)}
           aria-controls="workout-details-collapse"
           aria-expanded={open}
         >
           {open ? 'Collapse' : 'Expand'}
         </button>
       </div>
       <textarea
         className="form-control mb-4"
         rows="1"
         placeholder="Notes"
         value={completedWorkoutDetails.notes || ''}
         onChange={(e) => handleInputChange('notes', e.target.value)}
       />
       <Collapse in={open}>
         <div id="workout-details-collapse">
           <div className="row">
     <div className="col-md-4 col-4">
       <div className="form-floating" >
         <input
           type="number"
           className="form-control"
           placeholder="Estimated Length (minutes)"
           value={completedWorkoutDetails.duration || ''}
           onChange={(e) => handleInputChange('duration', e.target.value)}
           style={inputStyle}
         />
         {!isSmallScreen && (
           <label htmlFor="floatingInput">
             <i className="bi bi-clock-history me-2">  Minutes</i>
           </label>
         )}
       </div>
     </div>

     <div className="col-md-4 col-4">
       <div className="form-floating">
         <select
           className="form-select"
           value={completedWorkoutDetails.difficulty || ''}
           onChange={(e) => handleInputChange('difficulty', e.target.value)}
           style={inputStyle}
         >
           <option value="">Select difficulty</option>
           <option value="easy">Easy</option>
           <option value="medium">Medium</option>
           <option value="hard">Hard</option>
           <option value="insanity">Insanity</option>
         </select>
         {!isSmallScreen && (
           <label htmlFor="floatingSelect">
             <i className="bi bi-bar-chart-steps me-2"></i>Difficulty
           </label>
         )}
       </div>
     </div>

     <div className="col-md-4 col-4">
       <div className="form-floating">
         <input
           type="date"
           className="form-control"
           value={completedWorkoutDetails.dateAssigned}
           onChange={(e) => handleInputChange('dateAssigned', e.target.value)}
           required
           style={inputStyle}
         />
         {!isSmallScreen && (
           <label htmlFor="floatingInputDate">
             <i className="bi bi-calendar3 me-2"></i>Date Assigned
           </label>
         )}
       </div>
     </div>

     <div className="mt-6 d-flex justify-content-center align-items-center">
       <button
         type="button"
         className="btn btn-primary me-2"
         onClick={handleGenerateWorkout} // Call the internal handleGenerateWorkout function
         disabled={isGenerating}
       >
         {isGenerating ? 'Generating...' : 'Generate Workout'}
       </button>
       <button type="button" className="btn btn-secondary" onClick={handleOpenModal}>
         (Advanced) Edit Prompt
       </button>
     </div>
   </div>

         </div>
       </Collapse>

       {/* Modal for Editing the Prompt */}
       <Modal show={showModal} onHide={handleCloseModal}>
         <Modal.Header closeButton>
           <Modal.Title>Edit Prompt</Modal.Title>
         </Modal.Header>
         <Modal.Body>
           <textarea
             className="form-control"
             rows="8"
             value={modalPrompt}
             onChange={(e) => setModalPrompt(e.target.value)}
             placeholder="Edit the prompt for AI generation..."
           />
         </Modal.Body>
         <Modal.Footer>
           <Button variant="secondary" onClick={handleCloseModal}>
             Cancel
           </Button>
           <Button variant="primary" onClick={handleSavePrompt}>
             Save Changes
           </Button>
         </Modal.Footer>
       </Modal>
     </div>
   </div>
 );
};

export default WorkoutDetailsForm;