// Home.js
import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase/firebase';

import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import ClientNavbar from '../../layouts/ClientNavbar';

import ClientAddCreditsModal from '../../components/clientportal/ClientAddCreditsModal'; // Import the modal

import Profile from '../../components/clientportal/accountTabs/Profile';
import Payments from '../../components/shared/paymentsTableUI';

import { useLocation, useNavigate } from 'react-router-dom';




const Account = () => {
  //get information from the url
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get('tab');
  const navigate = useNavigate();

  const [refreshKey, setRefreshKey] = useState(0);

  // default tab is profile
  const [activeTab, setActiveTab] = useState('Profile');

  // client state objects
  const { username } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [credits, setCredits] = useState(''); 
  const user = auth.currentUser;
  
  
  // Modal state and open/close function
  const [isModalOpen, setModalOpen] = useState(false);
  const [coach, setCoachObject] = useState('');
  const [coachId, setCoachId] = useState('');

  const modalOpen  = async () => {
    setModalOpen(true);

    //Fetch Coach ID
    const clientDocRef = doc(db, "users", user.uid); // assuming user.uid is the client's UID
    const clientDocSnap = await getDoc(clientDocRef);
    if (!clientDocSnap.exists()) {
      throw new Error("Client document does not exist!");
    }
    const clientData = clientDocSnap.data();
    const coachUid = clientData.coachUid; // Getting coachUid from client's document

    if (!coachUid) {
      throw new Error("Coach UID not found in client's document!");
    }


    // Fetch coach's document to get stripeAccountId
    const coachDocRef = doc(db, "users", coachUid);
    const coachDocSnap = await getDoc(coachDocRef);
    if (!coachDocSnap.exists()) {
      throw new Error("Coach document does not exist!");
    }
    const coachData = coachDocSnap.data();
    setCoachObject(coachData);
    setCoachId(coachUid);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  // Fetch client data
  useEffect(() => {
    // Set active tab
    if (tab === 'payments') {
      setActiveTab('Payments');
    } else {
      setActiveTab('Profile'); // Default to profile or based on another condition
    }

    // Fetch client data
    const fetchData = async () => {
      console.log('A Firestore pull just happened!');
      const db = getFirestore();
      const userId = user.uid; // Replace with logic to get current user ID

      const userDocRef = doc(db, "users", userId);

      try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setFirstName(userData.firstName || '');
          setLastName(userData.lastName || '');
          setEmail(userData.email || '');       // Set email
          setPhoneNumber(userData.phoneNumber || ''); // Set phone number
          setCredits(userData.credits !== undefined && userData.credits !== null ? userData.credits : 'error');

          // Fetch coach data if coachUid exists
          if (userData.coachUid) {
            const coachDocRef = doc(db, "users", userData.coachUid);
            const coachDocSnap = await getDoc(coachDocRef);
            if (coachDocSnap.exists()) {
              const coachData = coachDocSnap.data();
              setCoachObject(coachData);
              setCoachId(userData.coachUid);
            }
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [user.uid, refreshKey, tab]);




  const handleSubmit = async (e) => { 
    e.preventDefault(); // Prevents the default form submit action

    const db = getFirestore(); // Get Firestore instance

    // Assuming you have a way to get the current user's ID
    const userId = user.uid; 

    const userDocRef = doc(db, "users", userId);

    try {
      await updateDoc(userDocRef, {
        firstName: firstName,
        lastName: lastName,
        email: email,             // Include email
        phoneNumber: phoneNumber  // Include phone number
      });
      alert('Profile updated successfully!');
    } catch (error) {
      console.error("Error updating document: ", error);
      alert('Error updating profile');
    }
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Profile':
        return <Profile 
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setPhoneNumber={setPhoneNumber}
          handleSubmit={handleSubmit}
        />;
      case 'Payments':
        return <Payments userId={user.uid} />;
      default:
        return <Profile />;
    }
  };
  

  return (
    <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
    {/* Vertical Navbar */}
      <ClientNavbar username={username} />
    {/* Main content */}
      <div className="h-screen flex-grow-1 overflow-y-lg-auto">
        {/* Header */}
        <header className="bg-surface-primary border-bottom pt-6 sticky-top">
          <div className="container-fluid">
            <div className="mb-npx">
              <div className="row align-items-center">
                <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                  {/* Title */}
                  <h1 className="h2 mb-0 ls-tight">Account Settings</h1>
                </div>
              </div>
              {/* Nav */}
              <ul className="nav nav-tabs mt-4 overflow-x border-0"></ul>
            </div>
          </div>
        </header>
        {/* Main */}
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-7 mx-auto">
                {/* Profile picture */}

                <div className="card pt-3 m-4 rounded-0">
                  <div className="card-body py-5 row">
                    <div className="col-auto position-relative d-inline-block text-white"> 
                      <div className="avatar rounded-circle">
                        <img
                          alt="..."
                          src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=random&color=fff&size=256`}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <span className="d-block h5 mb-1">{firstName} {lastName}</span>
                      {/* <span className="d-block text-sm">Last Appointment: </span> */}
                      <span className="text-sm font-semibold text-muted">Credits: {credits}</span>

                      {coach && coach.stripeAccountId && coach.allowClientPurchase && (
                        <div className="d-flex align-items-center mt-2">
                          <button onClick={modalOpen} className="btn btn-soft-primary btn-sm p-1"> + Add Credits</button>
                          {/* <button onClick={handleAddAppointmentClick className="btn btn-soft-primary btn-sm ms-3 p-1"> + Add Appointment </button> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Modal for buying credits */}
                {isModalOpen && (
                <ClientAddCreditsModal 
                  show={isModalOpen} 
                  onClose= {handleModalClose}
                  coachObject= {coach} 
                  coachId = {coachId}
                  clientId = {user.uid}
                  />
                )}

                  
                <ul className="nav nav-pills ms-4 pt-5">
                  <li className="nav-item">
                    <button 
                      className={`nav-link ${activeTab === 'Profile' ? 'active' : ''}`}  
                      onClick={() => {
                        setActiveTab('Profile');
                        navigate(`/website/${username}/account?tab=profile`);
                      }}
                    >Profile</button>
                  </li>
                  <li className="nav-item">
                    <button 
                      className={`nav-link ${activeTab === 'Payments' ? 'active' : ''}`}  
                      onClick={() => {
                        setActiveTab('Payments');
                        navigate(`/website/${username}/account?tab=payments`);
                      }}
                    >Payments</button>
                  </li>
                </ul>


                {/* Tab content */}

                <div className="card pt-3 m-4 rounded-0">
                  {renderTabContent()}
                </div>


              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Account;
