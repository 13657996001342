// WorkoutPreview.js
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import ClientNavbar from '../../layouts/ClientNavbar';
import { useIsSmallScreen } from '../../hooks/useWindowWidth';


const WorkoutPreview = () => {
    const isSmallScreen = useIsSmallScreen();
    const navigate = useNavigate();

    const { workoutId } = useParams();
    const [workout, setWorkout] = useState(null);
    const { username } = useParams(); 

    useEffect(() => {
        const fetchWorkout = async () => {
            const workoutRef = doc(db, 'assigned_workouts', workoutId);
            const workoutSnap = await getDoc(workoutRef);

            if (workoutSnap.exists()) {
                setWorkout(workoutSnap.data());
            } else {
                console.log('No such document!');
            }
        };

        fetchWorkout();
    }, [workoutId]);

    if (!workout) {
        return <p>Loading...</p>;
    }

    // Header logic
    let title;
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of day
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const workoutDate = new Date(workout.dateAssigned + 'T00:00:00');

    console.log('Today:', today);
    console.log('workout date', workoutDate);
    console.log('Today:', today.getTime());
    console.log('Workout Date:', workoutDate.getTime());

    if (workoutDate.getTime() === today.getTime()) {
        title = "Today's workout";
    } else if (workoutDate.getTime() === tomorrow.getTime()) {
        title = "Tomorrow's workout";
    } else if (workoutDate > today) {
        title = "Upcoming workout";
    } else {
        title = "Past workout";
    }

    // Helper function to get color for exercise
    const getColorForExercise = (index) => {
        const colors = [ '#ffc107', '#fd7e14', '#6f42c1','#17a2b8'];
        return colors[index % colors.length];
    };

    const handleBackClick = () => {
        navigate(`/website/${username}/scheduling`);
    };

    return (
        <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
            <ClientNavbar />
            <div className="h-screen flex-grow-1 overflow-y-lg-auto">

                <header className="bg-surface-primary border-bottom pt-6">
                    <div className="container-fluid">
                        <div className="mb-npx">
                            <div className="row align-items-center justify-content-between">
                                <div className="col mb-4 mb-sm-0">
                                    <h1 className="h2 mb-0 ls-tight">{title}</h1>
                                </div>
                                <div className="col-auto">
                                    <i className="bi bi-arrow-left" onClick={handleBackClick} style={{ cursor: 'pointer', fontSize: '1.5rem' }}></i>
                                </div>
                            </div>
                            <ul className="nav nav-tabs mt-4 overflow-x border-0"></ul>
                        </div>
                    </div>
                </header>

                <main className="py-6 bg-surface-secondary">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-7 mx-auto">

                                <div className={`${isSmallScreen ? 'pt-3 mb-4' : 'pt-3 m-4 rounded-0'} rounded-0 card`}>
                                    <div className="card-body d-flex justify-content-between align-items-center">
                                        <div className="mb-4">
                                            <h1 className="h2 mb-4 ls-tight">{workout.name}</h1>
                                            <p className="mb-0 mr-4 ms-auto">
                                                <i className="bi bi-calendar3 me-2"></i>
                                                {workout.dateAssigned}
                                            </p>
                                            {/* <p className="mr-4">
                                                <i className="bi bi-clock me-2"></i>
                                                {workout.duration} minutes
                                            </p> */}
                                            <p className="mr-4">
                                                <i className="bi bi-bar-chart me-2"></i>
                                                {workout.difficulty.charAt(0).toUpperCase() + workout.difficulty.slice(1)}
                                            </p>
                                        </div>
                                        <div>
                                            <a href={`/website/${username}/workout-start/${workoutId}`} className="btn btn-success rounded-circle p-4 d-flex align-items-center justify-content-center" style={{ width: '4rem', height: '4rem' }}>
                                                <i className="bi bi-play-fill" style={{ fontSize: '2rem' }}></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className={`pt-3 ${isSmallScreen ? 'mt-4' : ' card m-4'} rounded-0`}>
                                    <div className="card-body">
                                        <h5 className={`card-title ${isSmallScreen ? 'mb-6 mt-4' : ''}`}>Workout Details</h5>
                                        <div className="d-flex">
                                            <div className="bg-primary text-white d-flex align-items-center justify-content-center p-2 me-3" style={{ width: '60px', height: 'auto', borderRadius: '10px' }}>
                                                <p className="mb-0 text-center">{workout.duration} mins</p>
                                            </div>

                                            <div className="card w-100">
                                                {workout.exercises.map((exercise, index) => (
                                                        <div key={index} className="d-flex align-items-center mb-3 ms-5 me-5 mt-3">
                                                        <div className="border-start me-2" style={{ backgroundColor: getColorForExercise(index), width: '10px', height: '90%', borderRadius: '10px' }}></div>
                                                        <div>
                                                            <p className="mb-0">
                                                                <strong>{exercise.name}</strong>
                                                            </p>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="ms-1">{exercise.reps}</span>
                                                            <span className="text-muted ms-1">x {exercise.sets}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="text-center mt-8 mb-8">
                                            <Link to={`/website/${username}/workout-start/${workoutId}`}>
                                                <button className="btn btn-lg btn-success rounded-0">Start Workout</button>
                                            </Link>
                                        </div>
                                </div>

                             
                                
                                
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default WorkoutPreview;
