import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/firebase'; // Adjust the path as necessary
import { useParams, Link } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import ClientNavbar from '../../layouts/ClientNavbar';
import { getAuth } from 'firebase/auth';

// Import gym photos
import gym1 from '../../assets/gym_photos/photo1.jpg';
import gym2 from '../../assets/gym_photos/photo2.jpg';
import gym3 from '../../assets/gym_photos/photo3.jpg';
import gym4 from '../../assets/gym_photos/photo4.jpg';
import gym5 from '../../assets/gym_photos/photo5.jpg';
import gym6 from '../../assets/gym_photos/photo6.jpg';
import gym7 from '../../assets/gym_photos/photo7.jpg';
import gym8 from '../../assets/gym_photos/photo8.jpg';
import gym9 from '../../assets/gym_photos/photo9.jpg';

const gymPhotos = [gym1, gym2, gym3, gym4, gym5, gym6, gym7, gym8, gym9];

const Programs = () => {
  const [workouts, setWorkouts] = useState([]);
  const [fitnessGroupId, setFitnessGroupId] = useState(null);
  const [programType, setProgramType] = useState(null);
  const [groupName, setGroupName] = useState('');
  const { username } = useParams();
  const auth = getAuth();
  const clientId = auth.currentUser?.uid;  
  const scrollContainerRef = useRef(null); // Ref for the scrollable container\
  const emphasizedWorkoutRef = useRef(null);


  useEffect(() => {
    if (!clientId) {
      console.error('Client ID is undefined');
      return;
    }

    const fetchUserData = async () => {
      try {
        const userRef = doc(db, 'users', clientId);
        const userSnapshot = await getDoc(userRef);
        
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setFitnessGroupId(userData.fitness_group);
          setProgramType(userData.programType);

          if (userData.fitness_group) {
            const groupRef = doc(db, 'fitness_group', userData.fitness_group);
            const groupSnapshot = await getDoc(groupRef);
            if (groupSnapshot.exists()) {
              setGroupName(groupSnapshot.data().name || '');
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [clientId]);

  useEffect(() => {
    if (!fitnessGroupId && programType !== 'individual') {
      console.log("No fitness group and not individual program");
      return;
    }

    const fetchWorkouts = async () => {
      const today = new Date();
      const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      const fourteenDaysAhead = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);

      const workoutsRef = collection(db, "assigned_workouts");
      let q;

      if (programType === 'individual') {
        q = query(
          workoutsRef,
          where("clientId", "==", clientId),
          where("dateAssigned", ">=", sevenDaysAgo.toISOString().split('T')[0]),
          where("dateAssigned", "<=", fourteenDaysAhead.toISOString().split('T')[0]),
          orderBy("dateAssigned")
        );
      } else {
        q = query(
          workoutsRef,
          where("group", "==", fitnessGroupId),
          where("dateAssigned", ">=", sevenDaysAgo.toISOString().split('T')[0]),
          where("dateAssigned", "<=", fourteenDaysAhead.toISOString().split('T')[0]),
          orderBy("dateAssigned")
        );
      }

      const querySnapshot = await getDocs(q);

      const fetchedWorkouts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        dateAssigned: doc.data().dateAssigned,
      }));
      setWorkouts(fetchedWorkouts);
    };

    fetchWorkouts();
  }, [fitnessGroupId, programType, clientId]);

  useEffect(() => {
    if (emphasizedWorkoutRef.current) {
      emphasizedWorkoutRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [workouts]);
  
  const getTodayDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const getNextWorkoutDate = () => {
    const today = getTodayDate();
    const futureWorkouts = workouts.filter(workout => workout.dateAssigned >= today);
    return futureWorkouts.length > 0 ? futureWorkouts[0].dateAssigned : null;
  };

  const isEmphasisedWorkout = (workoutDate) => {
    const today = getTodayDate();
    const nextWorkoutDate = getNextWorkoutDate();
    return workoutDate === today || workoutDate === nextWorkoutDate;
  };

  const getGymPhotoByDate = (date) => {
    const dateObj = new Date(date);
    const dayOfYear = Math.floor((dateObj - new Date(dateObj.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
    return gymPhotos[dayOfYear % gymPhotos.length];
  };

  return (
    <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
      <ClientNavbar username={username} />

      <div className="h-screen flex-grow-1 overflow-y-lg-auto" ref={scrollContainerRef} style={{ WebkitOverflowScrolling: 'touch' }}>
        <header className="bg-surface-primary border-bottom py-4 sticky-top">
          <div className="container-fluid">
            <div className="mb-npx">
              <div className="row align-items-center justify-content-between">
                <div className="col mb-4 mb-sm-0">
                  <h1 className="h2 mb-0 ls-tight">Client Workouts</h1>
                  <p className="text-muted mb-0">
                    {programType === 'group' && !fitnessGroupId
                      ? 'Not set up in a fitness program. Contact your coach if you\'d like to sign up for something!'
                      : programType === 'group'
                        ? `Enrolled in ${groupName}`
                        : 'Your custom fitness plan'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="">
          <div className="">
            <div className="container-fluid bg-white px-0" >
              <div className="list-group">
                {workouts.length > 0 ? (
                  workouts.map((workout, index) => {
                    const isEmphasized = isEmphasisedWorkout(workout.dateAssigned);
                    return (
                      <div key={workout.id} ref={isEmphasized ? emphasizedWorkoutRef : null}  style={isEmphasized ? { scrollMarginTop: '-1000px' } : {}}>
                        <Link 
                          to={`/website/${username}/workout-preview/${workout.id}`} 
                          className={`list-group-item px-0 border-0 ${isEmphasisedWorkout(workout.dateAssigned) ? 'bg-light-peach' : ''}`} 
                          style={{
                            ...isEmphasisedWorkout(workout.dateAssigned) ? {
                              backgroundImage: `url(${getGymPhotoByDate(workout.dateAssigned)})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              paddingTop: '40px',
                              paddingBottom: '40px',
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                            } : {},
                            transition: 'height 0.3s ease'
                          }}
                        >
                          {isEmphasisedWorkout(workout.dateAssigned) && (
                            <div 
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                filter: 'contrast(0%) brightness(60%)',
                              }}
                            />
                          )}
                          <div style={{width: '95%', padding: '20px'}} className="mx-auto position-relative">
                            {isEmphasisedWorkout(workout.dateAssigned) && (
                              <div className="mb-2 text-white">
                                {workout.dateAssigned === getTodayDate() ? "Today's Workout" : "Next Workout"}
                              </div>
                            )}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className={`${isEmphasisedWorkout(workout.dateAssigned) ? 'h1 text-white' : 'h5'} mb-0`}>
                                {workout.name}
                              </div>
                              <span className={isEmphasisedWorkout(workout.dateAssigned) ? 'text-white' : 'text-muted'}>
                                {workout.dateAssigned}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                              {workout.duration === "" ? (
                                <span></span>
                              ) : (
                                <span className={isEmphasisedWorkout(workout.dateAssigned) ? 'text-white' : ''}>
                                  <i className="bi bi-clock me-2"></i>{workout.duration} minutes
                                </span>
                              )}
                              <span className="badge bg-primary">
                                {workout.difficulty.charAt(0).toUpperCase() + workout.difficulty.slice(1)}
                              </span>
                            </div>
                            {isEmphasisedWorkout(workout.dateAssigned) && (
                              <div className="text-center mt-6">
                                <button className="btn btn-primary">View Workout</button>
                              </div>
                            )}
                          </div>
                        </Link>
                        {index < workouts.length - 1 && <hr className="my-0 mx-auto" style={{borderTop: '1px solid #e0e0e0', width: '95%'}} />}
                      </div>
                    );
                  })
                ) : (
                  <p>No workouts assigned yet.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
