import { db } from '../firebase/firebase';
import { doc, collection, addDoc, updateDoc, getDoc, getDocs } from 'firebase/firestore';


export const addTransaction = async (userId, transactionData) => {

    const userRef = doc(db, 'users', userId);
    const transactionsCollection = collection(userRef, 'transactions');

    await addDoc(transactionsCollection, transactionData);
    
    
    // Fetch the current user's data
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists) {
        throw new Error('User does not exist!');
    }
    
    // Update the credits
    await updateDoc(userRef, {
        credits: (Number(userSnapshot.data().credits) + Number(transactionData.creditsPurchased))
    });

};

export const fetchTransactions = async (userId) => {
    console.log(userId);
    const userRef = doc(db, 'users', userId);
    const transactionsCollection = collection(userRef, 'transactions');

    const transactionsSnapshot = await getDocs(transactionsCollection);

    console.log("2");

    const transactions = [];
    transactionsSnapshot.forEach((doc) => {
        transactions.push({
            id: doc.id,
            ...doc.data()
        });
    });

    console.log(transactions);
    return transactions;
};
